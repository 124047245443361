<template>
    <div class="">
    <div class="MessageEntete1">
      <h2>DUO DANSE</h2>
    </div>
    <div  class="MessageEntete2">
      Simplement le bonheur de la danse.
    </div>
    <VueFlux 
        :options="options" 
        :rscs="rscs" 
        :transitions="transitions"
        ref= "$vueFlux">
        <template #preloader="preloaderProps">
            <FluxPreloader v-bind="preloaderProps" />
        </template>
        <template #pagination="paginationProps">
            <FluxPagination v-bind="paginationProps" />
        </template>
    </VueFlux>
    </div>
</template>
  
<script setup>
    import { ref, shallowReactive } from 'vue';
    import {
        VueFlux,
        FluxPagination,
        FluxPreloader,
        Img,
        Fade
    } from 'vue-flux';
    import 'vue-flux/style.css';

    const $vueFlux = ref();

    const options = shallowReactive({
        allowFullscreen: false,
        allowToSkipTransition: true,
        autohideTime: 2500,
        autoplay: true,
        bindKeys: false,
        delay: 5000,
        enableGestures: false,
        infinite: true,
        lazyLoad: true,
        lazyLoadAfter: 3,
    });

    const rscs = shallowReactive([
        new Img('/images/DUODANSE_web.jpg'),
        new Img('/images/Montage_web.jpg'),
        new Img('/images/Cloture2017_05_web.jpg'),
    ]);

    const transitions = shallowReactive([Fade]);

</script>
  
<style scoped>
    .VueFlux .FluxCaption{
        top: 64px;
    }
    .MessageEntete1,
    .MessageEntete2{
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        align-items: center;
        -webkit-box-align: center;
        -moz-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        flex-direction: column;
        -webkit-box-direction: normal;
        -moz-box-direction: normal;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        position:absolute;
        z-index:90;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        color: white;
        /* left: 20%; */
    }
    .MessageEntete1{
        font-family: "FultonsHandBold";
        top: 15vw;
        animation: entete1 5s;
        font-size: 6vw;
        /* font-size: 3rem; */
    }
    .MessageEntete2{
        font-family: "FultonsHandRegular";
        top: 26vw;
        animation: entete2 5s;
        font-size: 3vw;
    }
    @keyframes entete1 {
        0% {
            left: -80%;
            font-size: 0vw;
        }
        100% {
            left: 0%;
            font-size: 5vw;
        }
    }
    @keyframes entete2 {
        0% {
            left: 200%;
            font-size: 0vw;
        }
        100% {
            left: 0%;
            font-size: 2.5vw;
        }
    }
    .block-text{
        width: 100%;
        max-width: 90em;
        float: none;
        margin: 0;
        padding: 20px !important;
        position: relative;
    }
    h2{
        background: 0 0;
        /* font-size: 8vw; */
        font-family: "FultonsHandBold";
        font-weight: 500;
        font-style: normal;
        /* text-rendering: optimizeLegibility; */
        text-align: center !important;
        text-transform: none;
        color: #fff !important;
        line-height: 1.4;
        margin-top: 0.25rem;
        margin-bottom: 1rem;
        transition: font-size 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    /* p::before{
        content: "";
        width: 45vw;
        height: 2px;
        position: absolute;
        bottom: 0rem;
        background-image: linear-gradient(to left, rgba(0, 135, 206, 0.4), rgba(0, 70, 207, 0.9));
        box-sizing: border-box;
    } */
    p{
        font-family: "FultonsHandRegular";
        font-size: 3vw;
    }
    @media only screen and (max-width: 480px) { /*Mobile*/
        .MessageEntete1{
            /* font-size: 7vw; */
            animation: none;
        }
        .MessageEntete2{
            top: 28vw;
            font-size: 4vw;
            animation: none;
        }
    }   
</style>
  