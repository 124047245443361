<template>
  <!-- ======================================== -->
  <!-- Bloc formulaire de contact. -->
  <!-- ======================================== -->
  <div id="formActualite" ref="formActualite" class="container-formulaire">
    <h1>Article Items Actualité.</h1>
    <form method="POST" @submit.prevent="onSubmit">
      <div class="row jour">
        <div class="columns">
          <label for="">Jour:</label>
          <input type="text" name="jour" id="nom" placeholder="Le N° du jour." v-model="v$.form.jour.$model" v-bind:class="{'erreur' : v$.form.jour.$error}" />
            <!-- <div id="erreurnom" name="erreur" v-if="v$.form.nom.$error" class="error"></div> -->
            <!-- <div id="erreurnom" name="erreur" v-bind:class="{'errorcache' : !v$.form.nom.$error}" ></div> -->
            <!-- <div v-if="v$.form.jour.$error" class="error"></div> -->
            <!-- <div v-else class="errorcache"></div> -->
        </div>
      </div>

      <div class="row mois">
        <div class="columns">
          <label for="mois">Mois:</label>
          <input type="text" name="mois" id="mois" maxlength="9" placeholder="Le mois." v-model="v$.form.mois.$model"  v-bind:class="{'erreur' : v$.form.mois.$error}" />
          <!-- <div id="erreurmois" name="erreur" class="errorcache"></div> -->
          <!-- <div v-if="v$.form.telephone.$error" class="error">{{ v$.form.telephone.$error }}</div>
          <div v-else class="errorcache"></div> -->
        </div>
      </div>

      <div class="row lien">
        <div class="columns">
          <label for="lien">Lien:</label>
          <input type="text" name="lien" id="lien" placeholder="Lien." v-model="v$.form.lien.$model" v-bind:class="{'erreur' : v$.form.lien.$error}" />
          <!-- <div id="erreurlien" name="erreur" class="errorcache"></div> -->
          <!-- <div v-if="v$.form.email.$error" class="error">{{ v$.form.email.$error }}</div>
          <div v-else class="errorcache"></div> -->
        </div>
      </div>
      <div class="row message">
        <div class="columns">
          <label for="message">Message</label>
          <textarea type="text" name="message" id="message" placeholder="Votre message." v-model="v$.form.message.$model" v-bind:class="{'erreur' : v$.form.message.$error}"></textarea>
          <!-- <div id="erreurmessage" name="erreur" class="errorcache"></div> -->
          <!-- <div v-if="v$.form.message.$error" class="error">{{ v$.form.message.$error }}</div>
          <div v-else class="errorcache"></div> -->
        </div>
      </div>
      <div class="row submit-row">
        <div class="columns">
          <input type="submit" name="submit-button" class="buttonsubmit" value="Envoyer" />
        </div>
      </div>
      <div class="row mandatoryFieldsRow">
        <div class="columns">
          <label><span class="label-desc">*Ces champs sont obligatoires</span></label>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
// import { required, email, minLength,helpers, numeric, sameAs } from '@vuelidate/validators';
import { required, helpers, maxLength } from '@vuelidate/validators';
import axios from  "axios";

const validJour = helpers.regex(/^[0-9,et ]+$/)
const validMois = helpers.regex(/^[a-zA-ZéèàêÀ-ÿçÇ ]+$/)
// const validMessage = helpers.regex(/^[a-zA-Z0-9éèàêÀ.-ÿçÇ ]+$/)
const validMessage = helpers.regex(/^[0-9a-zA-Zéèàêëç.=!%?\-(),"'@ ]+(?:[-'\\s][0-9a-zA-Zéèàêëç.=!%?\-(),"'@ ]+)*$/)

export default {
  name: 'formActualite',
  props: ['afficheModaleActualite', 'basculeModaleActualite'],
  // computed: {
  //   console: () => console,
  // },
  components: {
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      // visible: false,
      form: {
        jour: '',
        mois: '',
        lien: '',
        message: ''
      }
    }
  },
  validations: {
      form: {
        jour: { required,
                validJour
        },
        mois: {required,
              max: maxLength(9),
              validMois
        },
        lien: {
        },
        message: { required,
                   validMessage
        }
      }
  },
  mounted: function(){
     this.form.lien="#Evenements";
    // Cette fonction fait apparaitre ou disparaitre le formulaire de contact pour
    // test pendant programmation.
    // Lorsque le formulaire de contact sera operationnel cette fonction n'aura
    // plus d'utilité.
    // var keys = {};
    /*
     * Mise du code appelé en commun dans une
     * fonction que nous allons
     * abonnée à un écouteur d'évènement.
     */
    // function trackMultipleKeyStroke (e) {
    //     e = e || event;
    //     // e.which = e.which || e.keyCode;
    //     keys[e.keyCode] = e.type === 'keydown';
    //     /*
    //      * Cette partie constitue le code exécuté quand
    //      * Ctrl (17), Alt (18) et E (69)
    //      * sont enfoncées.
    //      */
    //     if (keys[17] && keys[18] && keys[70]) {
    //         // console.log('Ctrl + Alt + f');
    //         keys = {};
    //         var formulaire = document.getElementById("formulaire");
    //         // var formulaire = document.getElementsByClassName("container-formulaire");
    //         if (formulaire.style.display == "none"){
    //           formulaire.style.display = "block";
    //         }else{
    //           formulaire.style.display = "none";
    //         }
    //     }
    // }
    // /*
    //  * Fonction de rétro-compatibilité pour
    //  * les navigateurs Internet Explorer.
    //  * Elle marchera dans tous les navigateurs
    //  * et demandera qui s'abonne, à quel évènement
    //  * et ce qu'il se passe quand l'évènement est
    //  * appelé / levé.
    //  */
    // function addEvent(element, event, func) {
    //     /*
    //      * Avons nous à faire à un vieil Internet Explorer ?
    //      */
    //     if (element.attachEvent) {
    //         /*
    //          * Abonnons nous alors comme Internet Explorer le propose.
    //          */
    //         return element.attachEvent('on' + event, func);
    //     } else {
    //         /*
    //          * Nous nous abonnons comme la spécification ECMAScript le propose.
    //          */
    //         return element.addEventListener(event, func, false);
    //     }
    // }
    // /*
    //  * Appel de la fonction de rétro-compatibilité
    //  * que nous venons de créer pour abonner `window`
    //  * au évènement `keydown` et `keyup`
    //  */
    // addEvent(window, "keydown", trackMultipleKeyStroke);
    // // addEvent(window, "keyup", trackMultipleKeyStroke);
  },
  methods: {
   onSubmit: function () {
     this.v$.form.$touch();
     if(!this.v$.form.$invalid){
       // alert(this.form.jour+" "+this.form.mois+" "+this.form.lien+" "+this.form.message);
         // this.form.jour="";
         // this.form.mois="";
         // this.form.lien="#Evenements";
         // this.form.message="";
         // this.v$.$reset();
         // alert("Actualité ajoutée.");

       const config = {
         responseType: 'text',
       };
       axios
        .post(
          '../php/formActualite-duodanse.php',
          {
            jour: this.form.jour,
            mois: this.form.mois,
            lien: this.form.lien,
            message: this.form.message
          },
          config,
        )
        .then(response => {
            // console.log('success', response.data.message);
            if (!response.data.error){
              this.form.jour="";
              this.form.mois="";
              this.form.lien="#Evenements";
              this.form.message="";
              this.v$.$reset();
              alert("Actualité ajoutée.");
            }
        })
        .catch(error => {
            console.log('Erreur', error.response.data);
        });
      }
   },
   controlechamp () {
    alert("Changement");
    if(this.v$.form.jour.$invalid){
      document.getElementById("jour").className = "erreur";
    }
   }
  }
};
</script>

<style scoped>
  .row{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    position: relative;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    transform: translate3d(0, 0, 0);
    -moz-transform: none;
    box-sizing: border-box;
    background-position: 50% -4px;
  }
  blockquote, dd, div, dl, dt, form, h4, h5, h6, li, ol, p, pre, td, th, ul {
      margin: 0;
      padding: 0;
      direction: ltr;
  }
  blockquote{
    text-align: center;
    quotes: "\201C""\201D";
    padding: 1.5rem;
    border: 0;
    line-height: 1.6rem;
    text-align: center;
    margin: 2rem 0;
    position: relative;
    direction: ltr;
  }
  blockquote p {
      font-size: 1.9rem;
      font-family: "Great Vibes", cursive;
      line-height: 140%;
      font-weight: 400;
      display: inline;

  }
  blockquote::after, blockquote::before {
      color: transparent;
      background: 0 0;
      content: "";
      width: 2rem;
      height: 2rem;
      border: solid #934239;
      position: absolute;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
  }
  blockquote::before {
      top: 0;
      left: 0;
      bottom: auto;
      right: auto;
      font-size: 4rem;
      line-height: 0.1rem;
      margin-right: 0.1rem;
      border-width: 1px 0 0 1px;
  }
  blockquote::after {
      bottom: 0;
      right: 0;
      top: auto;
      left: auto;
      font-size: 4rem;
      line-height: 0.1rem;
      margin-right: 0.1rem;
      border-width: 0 1px 1px 0;
  }
  /* .animation-scroll{
    transition: -webkit-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
                -moz-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
                -o-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
                transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
                opacity 1.2s cubic-bezier(0.4, 0.1, 0.2, 1) !important;
  } */
  .container-formulaire{
    /* display: none; */
    /* display: flex; */
    position: relative;
    width: 70%;
    /* max-width: 45em; */
    float: none;
    margin: 0 auto 0 auto;
    padding: 2em;
    border-radius: 18px;
    overflow: hidden; /*Garde l'image à l'interieur du container.*/
    opacity: 1;
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  }
  .block-form{
    border-radius: 8px !important;
    padding: 0;
    max-width: 38em !important;
    background-color: transparent !important;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    margin: 0 0 0 auto !important;
    /* --gridtemplate-padding: 0.25rem !important; */

  }
  form{
    margin: 0 auto !important;
    max-width: none;
    padding: 0;
    direction: ltr;
  }
  /************************************/
  .columns {
    display: flex;
    /* align-items: left; */
    /* justify-content: center; */
    flex-direction: column;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
      /* float: none; */
      margin: 0;
      padding: 0px 10px 0px 10px;
      width: 95%;
  }
  /* .column, .columns {
      position: relative;
  } */
  /* .row.section-ideal-bottomForm .block-html {
      margin: 0 !important;
      padding: 0 !important;
      max-width: none;
      width: auto;
  } */
  /* .in-view-up {
      opacity: 1;
  } */
  /* .animations-scroll {
      opacity: 0;
      -webkit-transition: -webkit-transform 1.2s cubic-bezier(.4,.1,.2,1),opacity 1.2s cubic-bezier(.4,.1,.2,1) !important;
      -moz-transition: -moz-transform 1.2s cubic-bezier(.4,.1,.2,1),opacity 1.2s cubic-bezier(.4,.1,.2,1) !important;
      -o-transition: -o-transform 1.2s cubic-bezier(.4,.1,.2,1),opacity 1.2s cubic-bezier(.4,.1,.2,1) !important;
      transition: -webkit-transform 1.2s cubic-bezier(.4,.1,.2,1),-moz-transform 1.2s cubic-bezier(.4,.1,.2,1),-o-transform 1.2s cubic-bezier(.4,.1,.2,1),transform 1.2s cubic-bezier(.4,.1,.2,1),opacity 1.2s cubic-bezier(.4,.1,.2,1) !important;
  } */
  /* .block-html .block-form {
      border-radius: 8px !important;
      max-width: 100%;
  } */
  /* .block-form {
      max-width: 38em !important;
      background-color: transparent !important;
      box-shadow: 0 19px 38px rgba(0,0,0,.3),0 15px 12px rgba(0,0,0,.22);
      margin: 0 0 0 auto !important;
  } */
  /* .home main .row .row {
      max-width: 90em;
      margin-left: auto;
      margin-right: auto;
  } */
  /* .block-form .row, .block-form .row .row {
      margin: 0;
  } */
  /* .row .row {
      width: auto;
  } */
  /* .row .row.collapse::after, .row .row.collapse::before, .row .row::after, .row .row::before, .row::after, .row::before {
      content: " ";
      display: table;
  } */
  /* ::after, ::before {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
  } */
  label{
    /* visibility: hidden; */
    display: none;
  }
  input:not([type="submit"]) {
      border: 2px solid blue;
      background: 0 0;
      /* background-color: rgba(0, 0, 0, 0); */
      background-color: #fff;
      /* color: #FFF; */
      color: rgba(0,0,0,.75);
      height: 1.5rem !important;
      border-radius: 8px !important;
      font-family: inherit;
      font-size: .875rem;
      font-style: normal;
      text-decoration:none;
      margin: .5rem .5rem 0 0;
      padding: .5rem;
  }
  .erreur{
    border: 3px solid red !important;
    /* font-style: oblique !important; */
    /* text-decoration:line-through !important; */
  }
  input[type="submit"]{
     margin: .5rem auto 0 auto;

  }
  /* input[type="text"],
  input[type="email"],
  input[type="tel"], */
  textarea {
      -webkit-appearance: none;
      -webkit-border-radius: 0;
      border-radius: 0;
      /* background-color: #fff; */
      /* font-family: inherit; */
      /* border: 2px solid #ccc; */
      border: 2px solid blue;
      -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
      box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
      /* color: rgba(0,0,0,.75); */
      display: block;
      font-size: .875rem;
      margin: .5rem 0 0 0;
      padding: .5rem;
      height: 10rem;
      width: 100%;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: -webkit-box-shadow .45s,border-color .45s ease-in-out;
      -moz-transition: -moz-box-shadow .45s,border-color .45s ease-in-out;
      transition: box-shadow .45s,border-color .45s ease-in-out;
  }
  a{
    color: rgb(79, 235, 230);
  }
  .error {
      display: flex;
      /* height: 1rem; */
      color: #fff;
      padding: .375rem .5625rem;
      margin: 1px 0 1px 0;
      font-size: .75rem;
      font-weight: 400;
      font-style: italic;
      background: #c60f13;
      width: fit-content;
  }
  .errorcache {
      display: flex;
      height: 1rem;
      content: '';
  }
  .buttonsubmit {
      background-color: #00a2fc;
      color: #FFFFFF;
      font-family: 'Open Sans',sans-serif;
      font-size: 1.125rem;
      width: 10rem;
      -webkit-transition: color .3s cubic-bezier(.4,0,.2,1),background .3s cubic-bezier(.4,0,.2,1),-webkit-box-shadow .3s cubic-bezier(.4,0,.2,1),border .3s cubic-bezier(.4,0,.2,1);
      -moz-transition: color .3s cubic-bezier(.4,0,.2,1),background .3s cubic-bezier(.4,0,.2,1),-moz-box-shadow .3s cubic-bezier(.4,0,.2,1),border .3s cubic-bezier(.4,0,.2,1);
      -o-transition: color .3s cubic-bezier(.4,0,.2,1),background .3s cubic-bezier(.4,0,.2,1),box-shadow .3s cubic-bezier(.4,0,.2,1),border .3s cubic-bezier(.4,0,.2,1);
      transition: color .3s cubic-bezier(.4,0,.2,1),background .3s cubic-bezier(.4,0,.2,1),box-shadow .3s cubic-bezier(.4,0,.2,1),border .3s cubic-bezier(.4,0,.2,1);
      border-radius: 40px !important;
      line-height: 1;
      /* padding: .8rem 1.8rem; */
      /* text-align: center; */
      -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.16);
      -moz-box-shadow: 0 2px 2px 0 rgba(0,0,0,.16);
      box-shadow: 0 2px 2px 0 rgba(0,0,0,.16);
  }
  .buttonsubmit:hover {
      background: transparent;
      color: white;
  }
  /************************************/
  @media only screen and (min-width: 480px) { /*Pc*/

  }
  @media only screen and (max-width: 480px) { /*Mobile*/
    .container-formulaire{
      width: 95%;
    }
  }
</style>
