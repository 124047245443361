<template>
<div id="ModaleNotice" class="Modale" v-if="afficheModale">
  <div class="overlay"  v-on:click="basculeModale" >
  </div>
  <div class="contenuModale">
    <header>
      <!-- <div class="droite"> -->
        <a href="#fermerModaleNotice" title="Fermer la fenètre" v-on:click="basculeModale" class="droite" >X</a>
      <!-- </div> -->
      <h2>Notice d'utilisation.</h2>
    </header>
    <section>
      <div id="texteModale">
        <p><u>Etape 1:</u></p>
            <p STYLE="padding:0 0 0 20px;">* Choisir le nombre de cours,</p>
            <p STYLE="padding:0 0 0 40px;">- Seul(e) selectionnez 1 </p>
            <p STYLE="padding:0 0 0 40px;">- En couple </p>
                <p STYLE="padding:0 0 0 60px;">avec paiement pour le couple selectionnez 2,</p>
                <p STYLE="padding:0 0 0 60px;">pour un paiement individuel faire deux inscriptions couple en selectionnant 1.</p>
            <p STYLE="padding:0 0 0 20px;">Possibilité de choisir un paiement en 1 ou 3 fois.</p>
        <br>
        <p><u>Etape 2:</u></p>
            <p STYLE="padding:0 0 0 20px;">* Renseignez vos informations individuelles.</p>
            <p STYLE="padding:0 0 0 20px;">* Choisir les options supplémentaires. Cette étapes est très importantes afin que nous sachions notament sur quel(s) cours vous êtes inscrits.</p>
            <p STYLE="padding:0 0 0 40px;">- Selectionnez votre type d'adhésion (Blanquefort ou Exterieure).</p>
            <p STYLE="padding:0 0 0 40px;">- Selectionnez les cours auxquels vous souhaitez participer. </p>
        <br>
        <p><u>Etape 3:</u></p>
            <p STYLE="padding:0 0 0 20px;">Renseignez les informations de la personne qui va effectuer le règlement.</p>
        <br>
        <p><u>Etape 4:</u></p>
            <p STYLE="padding:0 0 0 20px;">* Controlez le récapitulatif de votre inscription en vous assurant que vous avez bien sélectionné votre type d'adhésion et les cours auxquels vous souhaitez participer.</p>
            <p STYLE="padding:0 0 0 20px;">* Une contribution au fonctionnement de HelloAsso vous sera proposée avant la validation de votre paiement.</p>
            <p STYLE="padding:0 0 0 20px;">Cette proposition n'est en aucun cas obligatoire et peut être modifiée ou refusée.</p>
            <p STYLE="padding:0 0 0 20px;">Il vous suffit de cliquer sur le bouton modifier et de changer le montant de la contribution ou d'appuyer sur "je ne souhaite pas soutenir HelloAsso".</p>
        <br>
        <p><u>Etape 5:</u></p>
            <p STYLE="padding:0 0 0 20px;">Renseignez les informations de paiement et validez.</p>
        <br>
        <p>Ca y est vous êtes inscrit dans l'association Duo Danse et vous allez passer une saison formidable en notre compagnie.</p>
        <br>
      </div>
      <br>
      <div>
        <input type="button" id="btOk" value="OK"  v-on:click="basculeModale" >
      </div>
    </section>
  </div>
</div>
</template>

<script>

export default {
  name: 'ModaleNotice',
  props: ['afficheModale', 'basculeModale'],
  // computed: {
  //   console: () => console,
  // },
};
</script>

<style scoped>

  .Modale {
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .contenuModale {
    position: relative;
    margin: 1% auto;
    padding: 8px 8px 8px 8px;
    border-radius: 5px;
    background: #eee;
    transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
  }

  .contenuModale header, .contenuModale footer {
    /* width: 100%; */
    border-bottom: 1px solid #e7e7e7;
    border-radius: 5px 5px 0 0;
    /* background-color: lightgrey; */
    background-color: rgba(0, 135, 206, 0.9);
    padding:15px;
  }

  .contenuModale footer {
    border:none;
    border-top: 1px solid #e7e7e7;
    border-radius: 0 0 5px 5px;
  }

  .droite {
    float: right;
    width: 1em;
    right: 10px;
    text-align: right;
    font-weight: bold;
    font-size: 1.4em;
    color: white;
    text-decoration: none;

  }

  .contenuModale h2 {
    margin:0;
  }

  .contenuModale section {
    padding:15px 0 15px 0;
  }

  #texteModale {
    border:2px solid black;
    background-color:LightGray;
    text-align: left;
    height: 50vh;
    overflow-y: auto;
  }

  #texteModale p {
    margin: 0;
  }

  #btOk {
    cursor:pointer;
    background-color: rgba(0, 135, 206, 0.9);
    font-size: 1em;
    padding: 5px;
  }

  @media only screen and (min-width: 480px) { /*Pc*/
    .contenuModale {
      max-width: 40%;
      /* width:40%; */
    }
  }
  @media only screen and (max-width: 480px) { /*Mobile*/
    .contenuModale {
      max-width: 80%;
      /* width:40%; */
    }
  }
</style>
