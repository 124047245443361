<template>
  <div class="HeaderFloatingButton">
    <div class="block-button">
      <a href="https://goo.gl/maps/LubYRQhBYio" class="button" target="_blank">
        <span class="ico">
          <!-- Dessin localisation sur bouton1 . -->
          <!-- <svg width="32" height="32" viewBox="0 0 726.541 726.541" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" class="icon-localisation"> -->
          <svg width="32" height="32" viewBox="0 0 726.541 726.541" fill="#000000" class="icon-localisation">
            <!-- <g transform="translate(0.000000,726.541) scale(0.100000,-0.100000)" fill="#000000" stroke="none"> -->
            <path d="M187.248,332.646l176.023,240.535l176.022-238.67l-0.019-0.019c24.071-34.466,38.196-76.315,38.196-121.448
            C577.471,95.385,481.571,0,363.271,0s-214.2,94.841-214.2,211.859c0,44.89,14.125,86.516,38.197,120.768L187.248,332.646z
            M363.271,102.311c61.899,0,112.073,48.076,112.073,107.401c0,59.306-50.174,107.401-112.073,107.401
            s-112.074-48.086-112.074-107.401C251.197,150.387,301.371,102.311,363.271,102.311z M363.271,296.839
            c50.641,0,91.693-38.935,91.693-86.991c0-48.018-41.053-86.982-91.693-86.982s-91.693,38.954-91.693,86.982
            C271.577,257.905,312.63,296.839,363.271,296.839z M271.471,485.063V629.33l183.6,92.111V486.045l-91.8,127.82L271.471,485.063z
            M597.842,209.78c-0.185,50.407-15.592,97.162-41.801,135.679h0.02l-80.59,112.19v263.802l193.8-97.23V173.963L597.842,209.78z
            M170.51,343.371c-20.73-30.299-34.573-65.727-39.615-104.011l-73.625,36.924v450.257l193.8-97.211V456.445l-80.589-113.074
            H170.51z"
            />
            <!-- </g> -->
          </svg>
        </span>
        <span class="txt">La Renney</span>
      </a>
    </div>
    <div class="block-button button-style2">
      <!-- <a href="PDF/calendrierDuoDanse.pdf" class="button" target="_blank"> -->
      <a href="https://framagenda.org/apps/calendar/p/JP8m4fxwcjo4MWsH" class="button" target="_blank">
        <span class="ico">
          <!-- Dessin calendrier sur bouton2 . -->
          <!-- <svg width="32" height="32" viewBox="0 0 1280 1280" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#ffffff" class="icon-video1"> -->
            <svg width="32" height="32" viewBox="0 0 1280 1280" fill="#ffffff" class="icon-video1">
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none">
              <path
                  class="color1"
                  d="M513 12497 l-512 -302 -1 -6097 0 -6098 5255 0 5255 0 0 545 0 545 751 0 751 0 -79 83 c-167 177 -301 378 -446 667 -260 518 -401 1056 -484 1835
                  -16 148 -17 509 -20 4643 l-3 4482 -4978 0 -4977 0 -512 -303z m517 -4692 c0 -2764 4 -4244 10 -4358 43 -714 162 -1259 365 -1672 91 -187 175 -313 284
                  -428 104 -109 182 -170 280 -219 l76 -38 3887 0 3888 0 0 -200 0 -200 -4565 0 -4565 0 0 5554 0 5554 162 96 c89 53 165 96 170 96 4 0 8 -1883 8 -4185z
                  m2540 1550 l0 -965 -925 0 -925 0 0 965 0 965 925 0 925 0 0 -965z m2310 0 l0 -965 -1005 0 -1005 0 0 965 0 965 1005 0 1005 0 0 -965z m2310 0 l0 -965
                  -1005 0 -1005 0 0 965 0 965 1005 0 1005 0 0 -965z m2095 0 l0 -960 -897 -3 -898 -2 0 965 0 965 898 -2 897 -3 0 -960z m-6715 -2220 l0 -965 -925 0 -925
                  0 0 965 0 965 925 0 925 0 0 -965z m2310 0 l0 -965 -1005 0 -1005 0 0 958 c0 527 3 962 7 965 3 4 456 7 1005 7 l998 0 0 -965z m2310 0 l0 -965 -1005 0
                  -1005 0 0 965 0 965 1005 0 1005 0 0 -965z m2095 0 l0 -960 -897 -3 -898 -2 0 965 0 965 898 -2 897 -3 0 -960z m-6715 -1992 c0 -401 3 -833 7 -960 l6 -233
                  -931 0 -932 0 0 960 0 960 925 0 925 0 0 -727z m2310 -90 c0 -450 3 -882 7 -960 l6 -143 -1005 0 -1005 0 -7 92 c-3 50 -6 482 -6 960 l0 868 1005 0 1005
                  0 0 -817z m2310 -66 c0 -486 3 -918 6 -960 l7 -77 -1005 0 -1005 0 -7 53 c-3  28 -6 460 -6 960 l0 907 1005 0 1005 0 0 -883z m2100 -77 l0 -960 -897 2 -898
                  3 -3 958 -2 957 900 0 900 0 0 -960z m-6686 -1302 c3 -24 10 -92 17 -153 63 -632 242 -1224 478 -1582 l61 -93 -970 0 -969 0 -48 60 c-253 312 -409 920
                  -446 1743 l-3 67 937 0 938 0 5 -42z m2306 25 c0 -103 69 -572 115 -780 90 -405 246 -794 411 -1025 l34 -48 -958 0 -958 0 -46 48 c-314 324 -530 950
                  -603 1750 l-7 72 1006 0 c951 0 1006 -1 1006 -17z m2324 -155 c67 -665 245 -1256 487 -1615 27 -40 49 -76 49 -78 0 -3 -429 -5 -954 -5 l-954 0 -64 68
                  c-242 257 -432 722 -528 1297 -29 172 -60 418 -60 475 l0 30 1003 0 1003 0 18 -172z m2063 107 c18 -413 101 -977 198 -1330 29 -108 123 -393 150 -457 7 -17
                  -32 -18 -733 -18 l-740 0 -47 48 c-107 107 -225 290 -310 480 -150 339 -248 769 -298 1305 l-3 37 891 -2 890 -3 2 -60z"
              />
            </g>
          </svg>
        </span>
        <span class="txt">Calendrier Duo Danse</span>
      </a>
    </div>
    <div class="block-button">
      <a href="https://www.facebook.com/audrey.antoine.359" class="button" target="_blank">
        <span class="ico">
          <!-- Dessin facebook sur bouton3 . -->
          <!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 512 512" fill="#000000" class="icon-facebook"> -->
            <svg width="32" height="32" viewBox="0 0 512 512" fill="#000000" class="icon-facebook">
            <path class="color1" d="M293.5 494h-89.2V256h-59.5v-82l59.5 0 -0.1-48.3c0-66.9 18.1-107.6 97-107.6h65.6v82h-41c-30.7 0-32.2 11.5-32.2 32.9l-0.1 41.1h73.8l-8.7 82 -65 0L293.5 494z" />
          </svg>
        </span>
        <span class="txt">Facebook Duo Danse</span>
      </a>
    </div>
    <div class="block-button button-style2">
      <a href="https://duodanse33.us19.list-manage.com/subscribe?u=b64d5248e4a70b9bfcd357392&id=51382720c7" class="button" target="_blank">
        <span class="ico">
          <!-- Dessin newsletter sur bouton4 . -->
          <!-- <svg width="32" height="32" viewBox="0 0 166.781 166.781" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" class="icon-Bulletin"> -->
            <svg width="32" height="32" viewBox="0 0 166.781 166.781" fill="#000000" class="icon-Bulletin">
            <path d="M163.451,70.046l-32.35-20.847c-0.253-0.161-0.532-0.222-0.804-0.312v-7.19c0-1.92-1.554-3.475-3.475-3.475H113.92
              L86.97,21.378c-1.126-0.706-2.558-0.706-3.685,0l-26.95,16.844H39.958c-1.92,0-3.475,1.554-3.475,3.475v7.188
              c-0.272,0.09-0.552,0.152-0.804,0.314L3.329,70.046c-0.991,0.641-1.592,1.741-1.592,2.921v90.339c0,1.92,1.554,3.475,3.475,3.475
              h156.356c1.92,0,3.475-1.554,3.475-3.475V72.968C165.043,71.787,164.442,70.688,163.451,70.046z M85.128,28.423l15.681,9.799
              H69.447L85.128,28.423z M43.433,45.171h79.915v78.178c0,0.01,0.006,0.018,0.006,0.029l-11.754,7.137l-28.284-15.427
              c-1.055-0.57-2.338-0.567-3.386,0.034l-25.81,14.749l-10.692-6.492c0-0.01,0.006-0.018,0.006-0.028L43.433,45.171z M8.687,74.861
              l27.796-17.91v62.212L8.687,102.285V74.861z M8.687,110.412l38.537,23.397L8.687,155.831V110.412z M15.689,159.833l66.005-37.715
              l69.145,37.715H15.689z M158.094,155.874L118.65,134.36l39.444-23.949V155.874z M158.094,102.285l-27.797,16.877V56.951
              l27.797,17.911V102.285z"
            />
            <path d="M57.331,79.917h41.695c1.92,0,3.475-1.554,3.475-3.475V55.595c0-1.92-1.554-3.475-3.475-3.475H57.331
              c-1.92,0-3.475,1.554-3.475,3.475v20.847C53.856,78.363,55.411,79.917,57.331,79.917z M60.805,59.069h34.746v13.898H60.805
              V59.069z"
            />
            <rect x="53.856" y="86.866" width="55.593" height="6.949"
            />
            <rect x="53.856" y="100.765" width="55.593" height="6.949"
            />
            <path d="M147.67,41.697c0.889,0,1.778-0.339,2.457-1.018l12.283-12.283c1.357-1.357,1.357-3.556,0-4.913
              c-1.357-1.358-3.556-1.357-4.913,0l-12.283,12.283c-1.357,1.357-1.357,3.556,0,4.913
              C145.892,41.358,146.781,41.697,147.67,41.697z"
            />
            <path d="M16.654,40.679c0.679,0.679,1.568,1.018,2.457,1.018c0.889,0,1.778-0.339,2.457-1.018c1.357-1.357,1.357-3.556,0-4.913
              L9.284,23.483c-1.357-1.357-3.556-1.357-4.913,0c-1.357,1.357-1.357,3.556,0,4.913L16.654,40.679z"
            />
            <path d="M118.584,24.076c0.421,0.17,0.859,0.247,1.289,0.247c1.378,0,2.684-0.825,3.227-2.185l6.949-17.373
              c0.713-1.781-0.156-3.804-1.937-4.516c-1.764-0.709-3.804,0.149-4.516,1.937l-6.949,17.373
              C115.934,21.341,116.802,23.364,118.584,24.076z"
            />
            <path d="M47.155,22.139c0.543,1.361,1.849,2.185,3.227,2.185c0.431,0,0.869-0.078,1.289-0.248
              c1.781-0.713,2.65-2.735,1.937-4.516L46.659,2.187c-0.713-1.788-2.748-2.647-4.516-1.937c-1.781,0.713-2.65,2.735-1.937,4.516
              L47.155,22.139z"
            />
          </svg>
        </span>
        <span class="txt">Le Bulletin Duo Danse</span>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BoutonsLateraux_Vue',
    components: {
    },
  }
</script>

<style scoped>
  .HeaderFloatingButton {
    display:flex;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 1rem;
    margin: auto 0;
    pointer-events: none;
    z-index: 100;
    -webkit-box-align: end;
    -moz-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .block-button{
    pointer-events: all;
    height: inherit;
    margin: 1em 0 0;
    text-align: center;
  }
  .button{
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 1.8rem;
    padding: 1rem;
    overflow: hidden;
    border-radius: 40px !important;
    background: rgba(0, 135, 206, 0.9);
    -webkit-transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1), background 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: width 3s cubic-bezier(0.4, 0, 0.2, 1), background 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1), background 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transition: width 3s cubic-bezier(0.4, 0, 0.2, 1), background 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  div .button:hover{
    width: auto;
    background: rgba(0, 135, 206, 0.5);
    border-bottom: 5px solid white;
  }
  a {
    -webkit-appearance: none;
    text-decoration: none;
  }
  .ico{
    margin: 0;
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
  }
  .txt{
    padding-left: 1.1rem;
    white-space: nowrap;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 20ms;
    color: #fff;
    vertical-align: middle;
    display: inline-block;
    font-size: 1rem;
    text-transform: inherit;
    font-style: inherit;
    font-weight: inherit;
    margin: inherit;
    line-height: 1.2;
  }
  
  @media only screen and (max-width: 480px) { /*Mobile*/
    svg{
      width: 16px;
      height: 16px;
      margin: 10px 8px;
    }
    .button{
      padding: .2em .2em;
    }
    .txt{
    padding-left: .8rem;
    font-size: .8rem;
    line-height: .6;
    }
  }
</style>
