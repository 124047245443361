<template>
    <div v-on:mouseover="isOpen = true" v-on:mouseleave="isOpen = false" v-on:click="click">
        <!-- <a href ='#'> -->
        <div id="MCours">
            {{ title }}
        </div>
        <!-- </a> -->
        <svg viewBox="0 0 1030 638" width="10">
            <path d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z" fill="#FFF"></path>
        </svg>
        <transition name="fade" apear>
         <div class="sub-menu" v-if="isOpen">
            <div v-touch:tap="liaisonrouter"><router-link to="/#Professeurs" class="sub-menu-item">Professeurs</router-link></div>
            <div v-touch:tap="liaisonrouter"><router-link to="/#HorairesTarifs" class="sub-menu-item">Horaires</router-link></div>
            <div v-touch:tap="liaisonrouter"><router-link to="/#HorairesTarifs" class="sub-menu-item" >Tarifs</router-link></div>
        </div>
        </transition>
    </div>
</template>

<script>

export default {
  name: 'SMenuCours',
  props: {title: String,
          menuMobile: {
          type: Boolean,
          },
        },
  data () {
    return {
      isOpen: false,
      }
    },
  methods: {
    click: function(){
        this.isOpen = !this.isOpen;
    },
    mouseover: function(){
      if (this.menuMobile == false){
        this.isOpen = true;
      }
    },
    mouseleave: function(){
      if (this.menuMobile == false){
        this.isOpen = false;
      }
    },
    liaisonrouter: function() {
      if(this.$route.fullPath.includes("#")){
        var destination = this.$route.fullPath.slice(0,this.$route.fullPath.indexOf('#'))
        var section = this.$route.fullPath.slice(this.$route.fullPath.indexOf('#')+1)
        this.$router.push(destination)
        window.document.getElementById(section).scrollIntoView();
      }
    }
  }
}
</script>

<style scoped>
.sub-menu-item {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  color: #FFF;
  /* background-color: rgba(13, 13, 163,0.5); */
  background-color: rgba(0, 135, 206, 0.7);
  padding: 10px 20px;
  margin-left: 5px;
  position: relative;
  text-align: center;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-radius: 40px;
  transition: 0.4s;
  margin-bottom: 5px;
  text-decoration: none;
}
.sub-menu-item.active,
.sub-menu-item:hover {
  background-color: rgba(0, 135, 206, 0.9);
  border-bottom-color: white;
}
.sub-menu-item a {
  color: inherit;
  text-decoration: none;
}
.sub-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  border-radius: 0px 0px 16px 16px;
}
.fade-enter-active,
.fade-leave-active {
  transition: all .5s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.sub-menu {
  cursor: pointer;
}
@media only screen and (min-width: 480px) { /*Pc*/
  .sub-menu {
    /* margin-top: 5px; */
    top: calc(100% + 8px);
  }
}
@media only screen and (max-width: 480px) { /*Mobile*/
  .sub-menu {
    top: -20%;
    /* margin-top: -25%; */
    margin-left: 100%;
  }
  .sub-menu-item {
    padding: 0px 10px;
  }
}
</style>
