import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
// import Vue from "vue";
import Vue3TouchEvents from "vue3-touch-events";
import MasonryWall from '@yeger/vue-masonry-wall'

// Vue.use(Vue3TouchEvents);

// createApp(App)
//   // .use(router)
//   .mount('#app')

// import Vue2TouchEvents from 'vue2-touch-events'

// Vue.use(VueRouter)



const app = createApp(App)
app.use(router)
app.use(Vue3TouchEvents);
app.use(MasonryWall)


app.mount('#app')

// App.use(router).mount('#app')
// createApp({
//   data() {
//       return {
//           // title: "Hello Word Vue"
//       }
//   }
// }).mount('#app') 

// Vue.config.productionTip = false

// new Vue({
//   // router,
//   render: h => h(App)
// }).$mount('#app')
