<template>
    <nav id="MenuH">
        <div  class="menu-item" v-touch:tap="liaisonrouterMP()"><router-link to="/#Accueil">Accueil</router-link></div>
        <SMenuCours v-bind:menuMobile="false" class="menu-item" title="Cours" />
        <SMenuInscription v-bind:menuMobile="false" class="menu-item" title="Inscription" />
        <div class="menu-item" v-touch:tap="liaisonrouterMP()"><router-link to="/#Evenements" >Evènements</router-link></div>
        <SMenuMedias v-bind:menuMobile="false" class="menu-item" title="Médias" />
        <div class="menu-item" v-touch:tap="liaisonrouterMP()"><router-link to="#Contacts">Contacts</router-link></div>
    </nav>
</template>

<script>
import SMenuCours from '../components/SMenuCours'
import SMenuInscription from '../components/SMenuInscription'
import SMenuMedias from '../components/SMenuMedias'
export default {
  name: 'navbarMP_Vue',
  components: {
    SMenuCours,
    SMenuInscription,
    SMenuMedias
  },
  methods:{
    liaisonrouterMP: function() {
      if(this.$route.fullPath.includes("#")){
        // console.log("MP this.$route.fullPath = "+this.$route.fullPath)
        var destination = this.$route.fullPath.slice(0,this.$route.fullPath.indexOf('#'))
        var section = this.$route.fullPath.slice(this.$route.fullPath.indexOf('#')+1)
        // console.log("MP destination = "+destination)
        this.$router.push(destination)
        // console.log("MP section = "+section)
        // console.log(window.document.URL)
        if (window.document.getElementById(section)){
          window.document.getElementById(section).scrollIntoView();
        }
      }
    }
  }
}
</script>

<style scoped>

#MenuH {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  z-index:100;
  position:fixed;
  top: 20px;
  width: 100vw;
}

.menu-item {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  color: #FFF;
  font-size: 1.2em;
  font-weight: bold;
  background-color: rgba(0, 135, 206, 0.7);
  padding: 10px 15px;
  margin-left: 5px;
  position: relative;
  text-align: center;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-radius: 40px;
  transition: 0.4s;
  text-decoration: none;
}

.menu-item.active,
.menu-item:hover {
  background-color: rgba(0, 135, 206, 0.9);
  border-bottom-color: white;
  font-size: 1.3em;
  /* font-weight: normal; */
}

.menu-item a {
  color: inherit;
  text-decoration: none;
}

</style>
