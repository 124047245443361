<template>
  <div id="pageInscription">
    <div id="formInscription" >
      <div id="notice">
        <input type="button" id="btnotice" value="Notice d'utilisation"  v-on:click="basculeModale" >
      </div>
      <br>
      <iframe id="haWidget" allowtransparency="true" scrolling="auto"
      src="https://www.helloasso.com/associations/duo-danse/adhesions/inscriptions-2024-2025/widget"
      style="width: 100%; height: 750px; border: none;">
      </iframe>
    </div>
    <ModaleNotice  v-bind:afficheModale="afficheModale" v-bind:basculeModale="basculeModale" />
  </div>
</template>

<script>
  // import MenuPrincipal from '@/components/MenuPrincipal.vue'
  // import MenuMobile from '@/components/MenuMobile.vue'
  import ModaleNotice from '@/components/ModaleNotice.vue'

  export default {
    components: {
      // MenuPrincipal,
      // MenuMobile,
      ModaleNotice
    },
    data() {
      return {
        afficheModale: true,
      };
    },
    methods: {
      basculeModale() {
        this.afficheModale = !this.afficheModale;
      },
    }
  }
</script>

<style scoped>
  #pageInscription {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #202427;
    padding-left: 10px;
    padding-right: 5px;
  }
  #formInscription {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #39e3f4;
  }
  #notice {
    text-align: center;
    padding-left: 1em;
  }
  #btnotice {
    z-index: 1;
    font-size: 1.em;
    background-color: rgba(0, 135, 206, 0.9);
    cursor:pointer;
    padding: 5px;
  }
  /* @media only screen and (min-width: 480px) {
    #MenuH {
      display: block-inline;
    }
    #MenuM  {
      display:none;
    }
  }
  @media only screen and (max-width: 480px) {
    #MenuH {
      display:none;
    }
    #MenuM  {
     display: block-inline;
    }
    .parallax-videos{
      display: none;
    }
  } */
</style>
