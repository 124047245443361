<template>
<div id="HorairesTarifs" class="horairestarifs">
  <!-- Bloc de renseignements stylés 2. -->
  <div id="section-HorairesTarifs" class="row section-horairestarifs">
    <div id="image" class="container-image">
        <figure id="img_bloc" class="bloc-image">
            <a href="/images/DSLQ2018_08_web.jpg" class="blk-image__link lightbox">
                <img
                    src="/images/DSLQ2018_08_web.jpg"
                    data-src="/images/DSLQ2018_08_web.jpg"
                    alt="Association de danse Blanquefort"
                    width="954"
                    height="605"
                    class=""
                />
            </a>
        </figure>
    </div>
    <div id="texte-HorairesTarifs" class="container-texte-horairestarifs">
        <div id="text_HorairesTarifs-titre" class="block-text text-titre animation-scroll">
            <h2>Saison 2024-2025</h2>
            <br>
            <p>Démarrage des cours le 10 septembre 2024.</p>
        </div>
        <div id="text_BLOCK_21OZTGNDPJ" class="block-text blk-text">
            <table class="tableCours" >
                <thead>
                    <tr>
                        <th colspan="2">Salle polyvalente de l'école primaire de la Renney.<br> 11 rue de la Renney 33290 Blanquefort. <a href="https://goo.gl/maps/LubYRQhBYio" target="blank"> Plan d'accés.</a></th>
                    </tr>
                </thead>
                <tbody class="fondtableau1">
                    <tr>
                        <td colspan="2">Mardi - Audrey Bourgeois.</td>
                    </tr>
                    <tr>
                        <td >19h00 - 20h00</td>
                        <td >Danses de salon / Intermédiaires.</td>
                    </tr>
                    <tr>
                        <td >20h00 - 21h00</td>
                        <td >Danses de salon / Avancés.</td>
                    </tr>
                    <tr>
                        <td >21h00 - 22h00</td>
                        <td >Danses de salon / Techniques.</td>
                    </tr>
                    <tr>
                        <td >22h00 - 23h00</td>
                        <td >Cours Chorégraphique.</td>
                    </tr>
                </tbody>
                <tbody class="fondtableau3">
                    <tr>
                        <td colspan="2">Jeudi - Audrey Bourgeois.</td>
                    </tr>
                    <tr>
                        <td >19h00 - 20h00</td>
                        <td >Débutants - Rock, Salsa, Cha cha, Tango</td>
                    </tr>
                    <tr>
                        <td >20h00 - 21h00</td>
                        <td >Rock / Intermédiaires.</td>
                    </tr>
                    <!-- <tr>
                        <td >21h00 - 22h00</td>
                        <td >Rock / Avancés.</td>
                    </tr> -->
                    <tr>
                        <td style = "border-bottom-left-radius: 10px">21h00 - 22h00</td>
                        <td style = "border-bottom-right-radius: 10px">Rock / Avancés.</td>
                    </tr>
                    <!-- <tr >
                        <td style="border-bottom-left-radius: 10px">Cours chorégraphique</td>
                        <td style="border-bottom-right-radius: 10px">Nous consulter.</td>
                    </tr> -->
                </tbody>
            </table>
            <p style="font-size: 0.8em;">Cours chorégraphique et Techniques, accessible à tous les niveaux et inscription en couple obligatoire.</p>
            <p class="fondtableau3" style="border-style: solid; border-width: 2px; padding: 5px;
             border-radius: 10px; font-size: 0.8em;">Pour les cours (Intermédiaires, Avancés) de Rock et Danses de salon possibilité de suivre gratuitement le cours de niveau inférieur dans la mesure où les capacités de la salle et la gestion des personnes seules le permettent.</p>
            <table class="tableCours" >
                <thead>
                    <tr>
                        <th colspan="4">Rock, Salsa, Danses de salon</th>
                    </tr>
                    <tr>
                        <th colspan="2" rowspan="1"> </th>
                        <th >Seul</th>
                        <th >Couple</th>
                    </tr>
                </thead>
                <tbody class="fondtableau1">
                    <tr>
                        <td colspan="1" rowspan="2"><p style="Padding: 0; margin: 0; font-size: 14px;text-align: center;">Débutants</p><p style="Padding: 0; margin: 0; font-size: 12px; font-weight: normal;text-align: center;">(tarif promotionnel).</p></td>
                        <td>Trimestre</td>
                        <td>80,00€</td>
                        <td>140,00€</td>
                    </tr>
                    <tr>
                        <td>Année</td>
                        <td>180,00€</td>
                        <td>320,00€</td>
                    </tr>
                </tbody>
                <tbody class="fondtableau2">
                    <tr>
                        <td colspan="1" rowspan="2">1 cours</td>
                        <td>Année</td>
                        <td>190,00€</td>
                        <td>335,00€</td>
                    </tr>
                </tbody>
                <tbody  class="fondtableau1">
                    <tr>
                        <td colspan="1" rowspan="2">2 cours</td>
                        <td>Année</td>
                        <td>295,00€</td>
                        <td>495,00€</td>
                    </tr>
                </tbody>
                <tbody class="fondtableau2">
                    <tr>
                        <td colspan="1" rowspan="2">3 cours</td>
                        <td>Année</td>
                        <td>390,00€</td>
                        <td>660,00€</td>
                    </tr>
                </tbody>
                <tbody class="fondtableau1">
                    <tr>
                        <td style = "border-bottom-left-radius: 10px" colspan="1" rowspan="2">4 cours</td>
                        <td>Année</td>
                        <td>475,00€</td>
                        <td style = "border-bottom-right-radius: 10px">820,00€</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="text_BLOCK_Tarifs" class="block-text blk-text">
            <!-- <blockquote> -->
                <p>
                    A ces tarifs veuillez ajouter l'adhésion à l'association:<br>
                    Habitants de Blanquefort 15€. / Extérieurs 20€.
                </p>
                <hr style="border:1px solid red">
                <p>
                    Pour les inscriptions à l´année facilité de paiement <br>
                    en 3 fois non remboursable:<br>
                    <i>Chèques débités à l'inscription, Décembre et Mars</i>
                </p>
                <hr style="border:1px solid red">
                <p>
                    Les chèques vacances sont acceptés en paiement<br>
                    mais nous ne rendons pas de monnaie et en aucun cas ils seront remboursés.<br>
                </p>
            <!-- </blockquote> -->
        </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'HorairesTarifs',
  components: {
  },
}
</script>

<style scoped>
.horairestarifs{
  margin:2% auto 2% auto;
}
.row{
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -moz-transform: none;
  transform: none;
  width: auto;
  padding: 0;
  margin: 0 auto;
  max-width: 90em;
}
.section-horairestarifs{
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  box-sizing: border-box;
  float: none;
  position: relative;
  line-height: 16px;
  z-index: auto;
  /* height: 100vh; */
  /* maxwidth: 90em; */
  /* 1440x485.5 0 0*/
  /* border: 2px black solid; */
}
.card{
  border: 2px black solid;
}
.container-texte-horairestarifs{
  display: block;
  box-sizing: border-box;
  float:none;
  line-height: 16px;
  position:relative;
  z-index: 3;
  box-shadow: 12px 7px 35px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding:16px;
  width:45%;
  right: 5%;
  /* height: 100vh; */
  /* maxwidth: 90em; */
}
.block-text{
  margin: .5em auto 1.5em;
  padding: 0;
  opacity: 1;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  cursor: default;
  font-size: 16px;
}
.block-text a{
  color: #0e9bc1;
}
blockquote, dd, div, dl, dt, form, h1, h2, h3, h4, h5, h6, li, ol, p, pre, td, th, ul {
    margin: 0;
    padding: 0;
    direction: ltr;
}
p{
  font-size: 1.008rem;
  font-family: "Open Sans", sans-serif;
  color: #000;
  line-height: 1.2;
  text-rendering: optimizeLegibility;
  margin-bottom: 1.25rem;
  text-align: left;
}
blockquote{
  text-align: center;
  quotes: "\201C""\201D";
  padding: 1.5rem;
  border: 0;
  line-height: 1.6rem;
  text-align: center;
  margin: 2rem 0;
  position: relative;
  direction: ltr;
}
blockquote p {
    font-size: 1.9rem;
    font-family: "Great Vibes", cursive;
    line-height: 140%;
    font-weight: 400;
    display: inline;

}
blockquote::after, blockquote::before {
    color: transparent;
    background: 0 0;
    content: "";
    width: 2rem;
    height: 2rem;
    border: solid #934239;
    position: absolute;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
blockquote::before {
    top: 0;
    left: 0;
    bottom: auto;
    right: auto;
    font-size: 4rem;
    line-height: 0.1rem;
    margin-right: 0.1rem;
    border-width: 1px 0 0 1px;
}
blockquote::after {
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;
    font-size: 4rem;
    line-height: 0.1rem;
    margin-right: 0.1rem;
    border-width: 0 1px 1px 0;
}
h2{
  position: relative;
  color: #fc7100;
  color: rgba(0, 135, 206, 0.9);
  background: 0 0;
  font-size: 1.9rem;
  font-style: normal;
  text-align: left;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
}
h2::before {
    content: "";
    width: 90px;
    height: 2px;
    position: absolute;
    bottom: -8px;
    background-image: linear-gradient(to right, #fc7100, #934239);
    background-image: linear-gradient(to right, rgba(0, 135, 206, 0.9), rgba(0, 70, 207, 0.9));
}
/* .animation-scroll{
  transition: -webkit-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              -moz-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              -o-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              opacity 1.2s cubic-bezier(0.4, 0.1, 0.2, 1) !important;
} */
.container-image{
  width: 50%;
  /* max-width: 26.249994em; */
  margin: 0;
  padding: 16px;
  float: none;
  position: relative;
  /* left: 4.16667%; */
  right: auto;
}

.bloc-image{
  max-width: inherit;
  /* border: 1px red solid; */
  margin: 0 auto;
  padding: 0;
  border-radius: 8px;
  overflow: hidden; /*Garde l'image à l'interieur du container.*/
  opacity: 1;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}
a{
  transition: color 0.3s cubic-bezier(0.4, 0.1, 0.2, 1), background 0.3s cubic-bezier(0.4, 0.1, 0.2, 1);
  text-decoration: none;
  line-height: inherit;
}
img{
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
a img{
  border: none;
}
.bloc-image img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.3s ease, -webkit-box-shadow 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease, -moz-box-shadow 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease, box-shadow 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease, -moz-transform 0.3s ease, -o-transform 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease-in-out;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  -webkit-filter: brightness(1);
  -moz-filter: brightness(1);
  -ms-filter: brightness(1);
  filter: brightness(1);
  background: #fff;
  min-width: 100%;

}
.bloc-image img:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-filter: brightness(1.1);
  -moz-filter: brightness(1.1);
  -ms-filter: brightness(1.1);
  filter: brightness(1.1);
}
.tableCours{
	width: 100%;
	font-family: 'Raleway';
	border: 2px solid black;
	border-radius: 10px;
}
.tableCours thead tr:first-child th{
	/*border-top: 1px solid;*/
	border: 2px solid black;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.tableCours thead th{
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	background-color: #CCCCCC;
	border: 2px solid black;
}
.tableCours tbody tr:first-child td:first-child{
  font-weight: 600;
  text-align: center;
}
.tableCours tbody tr td {
	font-size: 14px;
	font-weight: 400;
	border: 2px solid black;
}
.fondtableau1{
  background: url(../assets/FondProg1.png);
  background-size: 100% 100%;
}
.fondtableau2{
  background: url(../assets/FondProg2.png);
  background-size: 100% 100%;
}
.fondtableau3{
    background: url(../assets/FondProg3.png);
    background-size: 100% 100%;
}
@media only screen and (max-width: 480px) { /*Mobile*/
  .section-horairestarifs{
    flex-direction: column-reverse;
    flex-direction: column;
    -webkit-box-direction: normal-reverse;
    -moz-box-direction: normal-reverse;
    -webkit-box-orient: vertical-reverse;
    -moz-box-orient: vertical-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
  }
  .container-texte-horairestarifs{
    padding:1%;
    width:98%;
    right: 0;
  }
  .container-image{
    z-index: 5;
    width: 80%;
    padding: 0 0 8% 0;
    top: -20px;

  }
}
</style>
