<template>
<div id="Evenements" class="evenements">
  <!-- Bloc de renseignements stylés 1. -->
  <div id="section-Evenements" class="row section-evenements">
    <div id="texte-Evenements" class="container-texte-evenements">
      <div id="text-Evenements-titre" class="block-text text-titre animation-scroll">
        <h2>{{evenements.textEvenementTitre}}</h2>
      </div>
      <div id="text-Evenements-1" class="block-text">
        <p v-html="evenements.textEvenement1">
        </p>
      </div>
      <div id="textEvenements2" class="block-text">
        <blockquote>
          <p><u>{{evenements.textEvenement2}}</u></p>
        </blockquote>
      </div>
    </div>
  <!-- </div> -->
    <!-- Image d'illustration du bloc de renseignements-->
    <div id="image" class="container-image">
        <figure id="img_bloc" class="bloc-image">
            <a :href="evenements.lien" target="_blank" class="blk-image__link lightbox">
                <!-- <img
                    src="../../public/images/Affiches-Soirees-Automne.gif"
                    data-src="../../public/images/Affiches-Soirees-Automne.gif"
                    alt="Evenements Duo Danse"
                    width="200"
                    height="400"
                    class=""
                /> -->
                <img
                    :src="evenements.imagesrc"
                    :data-src="evenements.imagedatasrc"
                    :alt="evenements.imagealt"
                    :width="evenements.imagewidth"
                    :height="evenements.imageheight"
                    :class="evenements.imageclass"
                />
            </a>
        </figure>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Evenements_Vue',
  data() {
    return {
      evenements: {},
    };
  },
  methods: {
    donneesevenements() {
      fetch('json/evenements.json')
      .then(response => response.json())
      .then(data => {
        this.evenements=data
        // console.log(this.evenements)
      })
      .catch(console.error);
    }
  },
  created() {
    this.donneesevenements();
  }
};
</script>

<style scoped>
.evenements{
  margin:2% auto 2% auto;
}
.row{
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -moz-transform: none;
  transform: none;
  width: auto;
  padding: 0;
  margin: 0 auto;
  max-width: 90em;
}
.section-evenements{
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  box-sizing: border-box;
  float: none;
  position: relative;
  line-height: 16px;
}
.container-texte-evenements{
  display: block;
  box-sizing: border-box;
  float:none;
  line-height: 16px;
  position:relative;
  box-shadow: 12px 7px 35px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding:16px;
  width:50%;
}
.block-text{
  margin: .5em auto 1.5em;
  padding: 0;
  opacity: 1;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  cursor: default;
  font-size: 16px;
}
blockquote, dd, div, dl, dt, form, h1, h2, h3, h4, h5, h6, li, ol, p, pre, td, th, ul {
    margin: 0;
    padding: 0;
    direction: ltr;
}
p{
  font-size: 1.008rem;
  font-family: "Open Sans", sans-serif;
  color: #000;
  line-height: 1.2;
  text-rendering: optimizeLegibility;
  margin-bottom: 1.25rem;
  text-align: left;
}
blockquote{
  text-align: center;
  quotes: "\201C""\201D";
  padding: 1.5rem;
  border: 0;
  line-height: 1.6rem;
  text-align: center;
  margin: 2rem 0;
  position: relative;
  direction: ltr;
}
blockquote p {
    font-size: 1.4rem;
    font-family: "Great Vibes", cursive;
    line-height: 140%;
    font-weight: 400;
    display: inline;

}
blockquote::after, blockquote::before {
    color: transparent;
    background: 0 0;
    content: "";
    width: 2rem;
    height: 2rem;
    border: solid #934239;
    position: absolute;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
blockquote::before {
    top: 0;
    left: 0;
    bottom: auto;
    right: auto;
    font-size: 4rem;
    line-height: 0.1rem;
    margin-right: 0.1rem;
    border-width: 1px 0 0 1px;
}
blockquote::after {
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;
    font-size: 4rem;
    line-height: 0.1rem;
    margin-right: 0.1rem;
    border-width: 0 1px 1px 0;
}
h2{
  position: relative;
  color: #fc7100;
  color: rgba(0, 135, 206, 0.9);
  background: 0 0;
  font-size: 1.9rem;
  font-style: normal;
  text-align: left;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
}
h2::before {
    content: "";
    width: 90px;
    height: 2px;
    position: absolute;
    bottom: -8px;
    background-image: linear-gradient(to right, #fc7100, #934239);
    background-image: linear-gradient(to right, rgba(0, 135, 206, 0.9), rgba(0, 70, 207, 0.9));
}
/* .animation-scroll{
  transition: -webkit-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              -moz-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              -o-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              opacity 1.2s cubic-bezier(0.4, 0.1, 0.2, 1) !important;
} */
.container-image{
  width: 35%;
  max-width: 35em;
  margin: 0;
  padding: 16px;
  float: none;
  position: relative;
  right: 2%;
  left: auto;
}

.bloc-image{
  max-width: inherit;
  /* border: 1px red solid; */
  margin: 0 auto;
  padding: 0;
  border-radius: 8px;
  overflow: hidden; /*Garde l'image à l'interieur du container.*/
  opacity: 1;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}
a{
  transition: color 0.3s cubic-bezier(0.4, 0.1, 0.2, 1), background 0.3s cubic-bezier(0.4, 0.1, 0.2, 1);
  text-decoration: none;
  line-height: inherit;
}
img{
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
a img{
  border: none;
}
.bloc-image img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.3s ease, -webkit-box-shadow 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease, -moz-box-shadow 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease, box-shadow 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease, -moz-transform 0.3s ease, -o-transform 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease-in-out;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  -webkit-filter: brightness(1);
  -moz-filter: brightness(1);
  -ms-filter: brightness(1);
  filter: brightness(1);
  background: #fff;
  min-width: 100%;

}
.bloc-image img:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-filter: brightness(1.1);
  -moz-filter: brightness(1.1);
  -ms-filter: brightness(1.1);
  filter: brightness(1.1);
}
@media only screen and (max-width: 480px) { /*Mobile*/
  .section-evenements{
    flex-direction: column;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
  }
  .container-texte-evenements{
    padding:5px;
    width:98%;
    /* height: 100vh; */
    /* maxwidth: 90em; */
  }
  h2{
    font-size: 1.5rem;
  }
  .container-image{
    width: 80%;
    right: 0;
    padding: 0 0 25px 0;
    top: -50px;
  }
}
</style>
