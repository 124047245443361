import {createRouter, createWebHistory} from 'vue-router'
import PagePrincipale from '@/components/PagePrincipale.vue'
import PageInscriptionLigne from '@/components/PageInscriptionLigne.vue'
import GalerieDuodanse from '@/components/GalerieDuodanse.vue'
// import GalerieDuodanseOld2 from '@/components/GalerieDuodanseOld2.vue'
import HorairesTarifs from '@/components/HorairesTarifs.vue' 

// const router = new VueRouter ({
const router = createRouter({
    history: createWebHistory(),
    // history: createWebHashHistory(),
    routes:[
        {
        path: '/',
        name: PagePrincipale,
        component: PagePrincipale
        },
        {
        path: '/PageInscriptionLigne',
        name: PageInscriptionLigne,
        component: PageInscriptionLigne
        },
        {
          // path: '/GalerieDuodanse',
          path: '/GalerieDuodanse',
          name: GalerieDuodanse,
          component: GalerieDuodanse
        },
        {
        path: '/HorairesTarifs',
        name: HorairesTarifs,
        component: HorairesTarifs
        }
    ]
    })

export default router;