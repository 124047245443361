<template>
    <div class="parallax-container">
        <FluxParallax
          :rsc="rsc"
          type="fixed"
          offset="50%"
          style="height: 800px"
        >
        <div id="container-texte" class="">
          <div id="bloc-texte" class="block-text">
              <h2>{{props.commentaire1}}</h2>
              <p>{{props.commentaire2}}</p>
          </div>
        </div>
      </FluxParallax>
    </div>
  </template>

  <script setup>
  import { defineProps } from 'vue';
  import {
    Img,
    FluxParallax
  } from 'vue-flux';

  const props = defineProps({
    imageVFP: String,
    commentaire1: String,
    commentaire2: String
  });
 
  const rsc = new Img(props.imageVFP);
  // const rsc = new Img({image});
  // const height = ref('250px');
  // const offset = ref('50%');
  
  </script>
  
  <style scoped>
  /* Styles spécifiques à votre composant parallax ici */
  .parallax-container {
    height: 800px; /* Ajustez la hauteur selon vos besoins */
    overflow: hidden;
  }
  
  /* .parallax-item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .parallax-item img {
    width: 100%;
    height: auto;
  } */

  .block-text{
    width: 100%;
    max-width: 90em;
    float: none;
    margin: 0;
    padding: 20px !important;
    position: relative;
  }

  #container-texte::before{
    box-sizing: border-box;
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    content: "" !important;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    background-image: linear-gradient(150deg, rgba(0, 135, 206, 0.2) 20%, rgba(0, 0, 0, 0.48) 60%, rgba(0, 0, 0, 0.47) 90%) !important;
  }

  #container-texte{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    height:100%;
    /*padding-top: auto;
    padding-bottom: auto; */
  }

  #container-texte::after{
    box-sizing: border-box;
    display: table;
    clear: both;
  }

  h2{
    position: relative;
    background: 0 0;
    font-size: 2rem !important;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-style: normal;
    text-rendering: optimizeLegibility;
    text-align: center !important;
    text-transform: none;
    color: #fff !important;
    line-height: 1.1;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    transition: font-size 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  h2::before{
    content: "";
    width: 90px;
    height: 2px;
    position: absolute;
    bottom: -8px;
    background-image: linear-gradient(to right, rgba(0, 135, 206, 0.9), rgba(0, 70, 207, 0.9));
    box-sizing: border-box;
  }

  p{
    font-size: 1.3rem;
    margin-top: 1.4rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    text-align: center !important;
    text-transform: none;
    color: #fff !important;
    line-height: 1.5;
  }

  @media only screen and (max-width: 480px) { /*Mobile*/
    /* flux-parallax{
      height: 100px;
    } */
  }
  </style>
  