<template>
  <div id="app" v-on:click="basculeMenuM = false">
    <FluxEntete id="entete" />
    <MenuPrincipal id="MenuH" />
    <MenuMobile  id="MenuM" />
    <BoutonsLateraux />
    <router-view></router-view>
    <Contacts />
  </div>

</template>

<script>

import FluxEntete from '@/components/vueflux_entete.vue'
// import FluxParallax from '@/components/vueflux_parallax.vue'
import MenuPrincipal from '@/components/MenuPrincipal.vue'
import MenuMobile from '@/components/MenuMobile.vue'
import BoutonsLateraux from '@/components/BoutonsLateraux.vue'
import Contacts from '@/components/Contacts.vue'

export default {
  name: 'App',
  components: {
    FluxEntete,
    MenuPrincipal,
    MenuMobile,
    BoutonsLateraux,
    Contacts
  }
}
</script>

<style scoped>
  @font-face {
      font-family: FultonsHandRegular;
      font-style: normal;
      font-weight: 400;
      src: local("FultonsHand_Regular"), local("FultonsHand_Regular"), url(/public/fonts/FultonsHand_Regular.ttf) format("truetype");
  }
  @font-face {
      font-family: FultonsHandBold;
      font-style: normal;
      font-weight: 900;
      src: local("FultonsHand_Bold"), local("FultonsHand_Bold"), url(/public/fonts/fultonshand-bold.ttf) format("truetype");
  }
  @font-face {
      font-family: AboutYou;
      font-style: normal;
      font-weight: 400;
      src: local("About_You"), local("About_You"), url(/public/fonts/About_You/About_You.ttf) format("truetype");
  }
  @font-face {
      font-family: Franklin Gothic Moyen;
      font-style: normal;
      font-weight: 400;
      src: local("Franklin_Gothic_Moyen"), local("Franklin_Gothic_Moyen"), url(/public/fonts/Franklin_Gothic_Moyen/framd.ttf) format("truetype");
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    color: #b4b8bd;
    font-family: 'montserrat', sans-serif;
  }
  @media only screen and (min-width: 480px) {
    #MenuH {
      display: block-inline;
    }
    #MenuM  {
      display:none;

    }
  }
  @media only screen and (max-width: 480px) {
    #MenuH {
      display:none;
    }
    #MenuM  {
     display: block-inline;
    }
    /* .parallax-videos{
      display: none;
    } */
  }
</style>
