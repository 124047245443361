<template>
  <main>
    <div id="icone-hamburger" v-show='!basculeMenuM' @click.prevent='ioMenu()'>
      <img src="../assets/menu-hamburger-bleu.png" width="40vw" height="40vw" >
    </div>
    <nav id="MenuM" v-show='basculeMenuM'>
        <router-link id="btAccueil" class="menu-item" v-touch="liaisonrouterMM()" to="/#Accueil">Accueil</router-link>
        <div class="menu-item" v-on:click.prevent="ioCours()">Cours
          <svg viewBox="0 0 1030 638" width="10">
            <path d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z" fill="#FFF"></path>
          </svg>
        </div>
        <div id="btCours" class="sous-menu" v-show='basculecours'>
          <router-link class="sous-menu-item" v-touch="liaisonrouterMM()" to="/#Professeurs">Professeurs</router-link>
          <router-link class="sous-menu-item" v-touch="liaisonrouterMM()" to="/#HorairesTarifs">Horaires</router-link>
          <router-link class="sous-menu-item" v-touch="liaisonrouterMM()" to="/#HorairesTarifs">Tarifs</router-link>
        </div>
        <div class="menu-item" v-on:click.prevent='ioInscription()'>Inscription
          <svg viewBox="0 0 1030 638" width="10">
            <path d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z" fill="#FFF"></path>
          </svg>
        </div>
        <div id="btInscription" class="sous-menu" v-show='basculeinscription'>
          <div class="sous-menu-item" @click="ChargeBulletinPDF">
            <a href="">Bulletin</a>
          </div>
          <router-link class="sous-menu-item" v-touch="liaisonrouterMM()" to="/PageInscriptionLigne">En ligne</router-link>
        </div>
        <router-link id="btEvenements" class="menu-item" v-touch="liaisonrouterMM()" to="#Evenements" >Evènements</router-link>
        <div class="menu-item"  v-on:click.prevent='ioMedias()'>Médias
          <svg viewBox="0 0 1030 638" width="10">
            <path d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z" fill="#FFF"></path>
          </svg>
        </div>
        <div id="btMedia" class="sous-menu" v-show='basculemedias'>
          <router-link class="sous-menu-item" v-touch="liaisonrouterMM()" to="/GalerieDuodanse#content">Photos</router-link>
        </div>
        <router-link id="btContacts" class="menu-item" v-touch="liaisonrouterMM()" to="#Contacts" >Contacts</router-link>
    </nav>
  </main>
</template>

<script>

export default {
  name: 'navbarMM_Vue',
  components: {
  },
  // props: ['basculeMenuM'],
  data () {
    return {
      basculeMenuM: false,
      basculecours: false,
      basculeinscription: false,
      basculemedias: false,
      compteur1: 0,
      compteur2: 0,
    }
  },
  methods:{
    liaisonrouterMM: function() {
      // console.log("compteur2 = " + this.compteur2++)
      // console.log("liaison this.$route.fullPath = "+this.$route.fullPath)
      if(this.$route.fullPath.includes("#")){
        // console.log("MM this.$route.fullPath = "+this.$route.fullPath)
        var destination = this.$route.fullPath.slice(0,this.$route.fullPath.indexOf('#'))
        // console.log("MM Destination = "+destination)
        var section = this.$route.fullPath.slice(this.$route.fullPath.indexOf('#')+1)
        // console.log("MM Section = "+section)
        this.$router.push(destination)
        if (window.document.getElementById(section)){
          window.document.getElementById(section).scrollIntoView();
        }
      //   // Traite le click des boutons sans sous menu.
        // if (event.target.id == "btAccueil" || event.target.id == "btContacts" || event.target.id == "btEvenements") {
        //   // this.ioMenu()
        //   this.basculeMenuM = !this.basculeMenuM
          this.basculeMenuM = false
          this.basculecours = false
          this.basculeinscription = false
          this.basculemedias = false
        // }
        // if (event.target.parentElement.id == "btCours") {
        //   console.log(event.target.parentElement.id)
        //   this.ioCours()
        //   this.ioMenu()
        // }
      //   if (event.target.parentElement.id == "btInscription") {
      //     this.ioInscription()
      //     this.ioMenu()
      //   }
      //   if (event.target.parentElement.id == "btMedia") {
      //     this.ioMedias()
      //     this.ioMenu()
      //   }
      }
    },
    ChargeBulletinPDF() {
      const url = "PDF/Bulletin d'inscriptionWeb2024-2025.pdf";
      window.open(url, '_blank');
      this.ioMenu();
      this.ioInscription()
    },
    ioMenu: function(){
      this.basculeMenuM = !this.basculeMenuM
    },
    ioCours: function(){
      this.basculecours = !this.basculecours
    },
    ioInscription: function(){
      this.basculeinscription = !this.basculeinscription
    },
    ioMedias: function(){
      this.basculemedias = !this.basculemedias
    }
  }
}
</script>

<style scoped>

main {
  z-index:99;
  position:fixed;
  top: 50%;
  right: 20px;
  width: auto;
}

#MenuM {
  position:fixed;
  top: .5em;
  left: 1em;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: left;
  -webkit-box-align: left;
  -moz-box-align: left;
  -webkit-align-items: left;
  -ms-flex-align: left;
  justify-content: left;
  -webkit-box-pack: left;
  -moz-box-pack: left;
  -ms-flex-pack: left;
  -webkit-justify-content: left;
  flex-direction: column;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
}

.menu-item {
  color: #FFF;
  background-color: rgba(0, 135, 206, 0.7);
  padding: 0px 10px;
  margin-left: 5px;
  margin-bottom: 10px;
  position: relative;
  text-align: center;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-radius: 40px;
  transition: 0.4s;
  text-decoration: none;
  cursor: pointer;
}

.sous-menu{
  margin-left: 25px;
}

.sous-menu-item{
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  color: #FFF;
  background-color: rgba(0, 135, 206, 0.7);
  padding: 0px 10px;
  margin-left: 5px;
  margin-bottom: 10px;
  position: relative;
  text-align: center;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-radius: 40px;
  transition: 0.4s;
  text-decoration: none;
}

.menu-item a, .sous-menu-item a{
  color: #FFF;
  text-decoration: none;
}

#icone-hamburger{
  padding-left: 2vw;
  text-align: left;
  cursor: pointer;
}

#icone-hamburger img{
  background-color: rgba(193, 208, 215, 0.61);
  border-radius: 10px;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 480px) { /*Mobile*/
}
  #icone-hamburger  {
  display: block-inline;
}

</style>
