<template>
  <div id="Contacts" class="contacts">
    <!-- ======================================== -->
    <!-- Bloc formulaire de contact. -->
    <!-- ======================================== -->
    <div id="section-contacts" class="row section-contacts">
      <div id="container-texte-contacts" class="container-texte-contacts">
        <div id="block-text-contacts" class="block-text">
          <h3>Contacts DUO DANSE.</h3>
          <br><br>
          <p>Philippe: 07 60 49 41 24 <br>
            Sylvie: 06 29 92 54 96 <br>
            Christine: 06 61 62 01 05 <br>
            Audrey: 06 12 26 67 00<br><br>
            Mail: duodanse@yahoo.fr</p>
        </div>
        <div id="carte" class="bloc-carte">
          <!-- <div id="gmap_map_BLOCK_XMNB8WEK0N_612762ca5ce62" class="planacces-map map_BLOCK_XMNB8WEK0N_612762ca5ce62 blk-map__planacces"></div> -->
          <iframe id="carte-renney" class="carte-renney" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2825.563634517634!2d-0.63038498446096!3d44.91187227909826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd54d66fadf24a8b%3A0x6433f589e7b6fa3b!2sEcole%20El%C3%A9mentaire%20La%20Renney!5e0!3m2!1sfr!2sfr!4v1642871856158!5m2!1sfr!2sfr"
           allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
      <div id="formulaire" class="container-formulaire">
        <h1>Posez vos questions à l'aide de notre formulaire de contact.</h1>
        <form ref="formContact" method="POST" @submit.prevent="onSubmit">

          <!-- <div class="row nom">
            <div class=" columns">
              <label for="nom">Nom *</label>
              <input type="text" name="f1-c0-nom_3" id="nom" placeholder="Votre nom complet" required="required" value="" />
              <div v-if="visible" class="error">Ce champ est obligatoire</div>
              <div v-else class="errorcache"></div>
            </div>
          </div> -->

          <div class="row nom">
            <div class=" columns">
              <label for=""> Nom:</label>
              <input type="text" name="nom" id="nom" placeholder="Votre nom complet" v-on:change="controlechamp" v-on:keyup.ctrl.alt.a="basculeModaleActualite" v-model="v.form.nom.$model" v-bind:class="{'erreur' : v.form.nom.$error}" />
                <div id="erreurnom" name="erreur" v-bind:class="{'errorcache' : !v.form.nom.$error}" ></div>
                <div v-if="v.form.nom.$error" class="error"></div>
                <div v-else class="errorcache"></div>
            </div>
          </div>

          <!-- <div class="row telephone">
            <div class="columns">
              <label for="telephone">Téléphone *</label>
              <input type="text" name="telephone" id="telephone" maxlength="10" placeholder="Votre numéro de téléphone" v-model="v.form.telephone.$model"  v-bind:class="{'erreur' : v.form.telephone.$error}" />
              <div id="erreurtelephone" name="erreur" class="errorcache"></div> -->
              <!-- <div v-if="v.form.telephone.$error" class="error">{{ v.form.telephone.$error }}</div>
              <div v-else class="errorcache"></div> -->
            <!-- </div>
          </div> -->
          <div class="row mail">
            <div class="columns">
              <label for="e-mail">E-mail *</label>
              <input type="email" name="email" id="e-mail" placeholder="Votre adresse e-mail" v-model="v.form.email.$model" v-bind:class="{'erreur' : v.form.email.$error}" />
              <!-- <div id="erreuremail" name="erreur" class="errorcache"></div> -->
              <!-- <div v-if="v.form.email.$error" class="error">{{ v.form.email.$error }}</div>
              <div v-else class="errorcache"></div> -->
            </div>
          </div>
          <div class="row message">
            <div class="columns">
              <label for="message">Précisions</label>
              <textarea type="text" name="message" id="message" placeholder="Votre message." v-model="v.form.message.$model" v-bind:class="{'erreur' : v.form.message.$error}"></textarea>
              <!-- <div id="erreurmessage" name="erreur" class="errorcache"></div> -->
              <!-- <div v-if="v.form.message.$error" class="error">{{ v.form.message.$error }}</div>
              <div v-else class="errorcache"></div> -->
            </div>
          </div>
          <!-- <div class="row fideoRow">
            <div class="columns">
              <input type="hidden" name="fideo" value="1" />
            </div>
          </div> -->
          <div class="row politiqueConfidentialiteCheckRow">
            <div class="columns">
              <div class="enligne">
                <input type="checkbox" name="rgpd" id="rgpd" class="politiqueConfidentialiteCheck"  v-model="v.form.rgpd.$model"  v-bind:class="{'erreur' : v.form.rgpd.$error}" />
                <label class="politiqueConfidentialiteCheckLabel" for="rgpd">
                    En soumettant ce formulaire, j&#039;accepte que les informations saisies soient exploitées dans le cadre strict de ma demande.
                </label>
              </div>
              <!-- <small class="error">Case à cocher obligatoire</small> -->
              <!-- <div v-if="v.form.rgpd.$error" class="error">{{ v.form.rgpd.$error }}</div>
              <div v-else class="errorcache"></div> -->
            </div>
          </div>
          <!-- <div class="row captchaRow">
            <div class="columns">
              <input type="hidden" name="recaptcha_1" value="1" /> -->
              <!-- <div class="g-recaptcha" data-sitekey="6LfeZ08UAAAAANjd_WGrJMGSNwZ341j0kJK251yl" data-callback="onRecaptchaSubmit" data-badge="inline" data-size="invisible"></div> -->
            <!-- </div>
          </div> -->
          <div class="row submit-row">
            <div class="columns">
              <input type="submit" name="submit-button" class="buttonsubmit" value="Envoyer" />
            </div>
          </div>
          <!-- <div class="row mandatoryFieldsRow">
            <div class="columns">
              <label><span class="label-desc">*Ces champs sont obligatoires</span></label>
            </div>
          </div> -->
        </form>
        <div class="row msgPolitiqueConfidentialiteRow">
          <div class="columns">
            <div class="msgPolitiqueConfidentialite">
              DUO DANSE s'engage à ce que la collecte et le traitement de vos données, effectués à partir de notre site <a href="/">duodanse33.fr</a>, soient conformes au règlement général sur la
              protection des données (RGPD) et à la loi Informatique et Libertés. Pour connaître et exercer vos droits, notamment de retrait de votre consentement à l'utilisation des données collectées par ce
              formulaire, veuillez consulter notre <a class="rgpdLink" href="#" target="_blank">politique de confidentialité</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModaleActualite  v-bind:afficheModaleActualite="afficheModaleActualite" v-bind:basculeModaleActualite="basculeModaleActualite" />
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
// import { required, email, minLength,helpers, numeric, sameAs } from '@vuelidate/validators';
import { required, email, helpers, sameAs } from '@vuelidate/validators';
import axios from  "axios";
import ModaleActualite from '@/components/ModaleActualite.vue'

// export function validName(name) {
//   let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
//   if (validNamePattern.test(name)){
//     alert("true");
//     return true;
//   }
//   alert("false");
//   return false;
// }

const validNom = helpers.regex(/^[a-zA-Zéèàê\- ]+(?:[-'\\s][a-zA-Zéèàê\- ]+)*$/)
const validMessage = helpers.regex(/^[0-9a-zA-Zéèàêç.=!%?\-(),"'@ ]+(?:[-'\\s][0-9a-zA-Zéèàêç.=!%?\-(),"'@ ]+)*$/)
// const validTel = helpers.regex(/^[0-9]+(?:[-'\\s][0-9]+)*$/)

export default {
  name: 'Contacts_Vue',
  components: {
    ModaleActualite
  },
  setup () {
    return { v: useVuelidate() }
  },
  data() {
    return {
      afficheModaleActualite: false,
      // visible: false,
      form: {
        nom: '',
        // telephone: '',
        email: '',
        message: '',
        rgpd: false
        }
      }
  },
  validations: {
    // return {
      form: {
        nom: {required,
              validNom
        },
        // telephone: { required,
        //              min: minLength(10),
        //              numeric
        // },
        email: { required,
                 email // Matches this.contact.email
        },
        message: { required,
                   validMessage
        },
        rgpd: { sameAs: sameAs(true)
        }
      }
    // }
  },
  // mounted: function(){
  //   // Cette fonction fait apparaitre ou disparaitre le formulaire de contact pour
  //   // test pendant programmation.
  //   // Lorsque le formulaire de contact sera operationnel cette fonction n'aura
  //   // plus d'utilité.
  //   var keys = {};
  //   /*
  //    * Mise du code appelé en commun dans une
  //    * fonction que nous allons
  //    * abonnée à un écouteur d'évènement.
  //    */
  //   function trackMultipleKeyStroke (e) {
  //       e = e || event;
  //       // e.which = e.which || e.keyCode;
  //       keys[e.keyCode] = e.type === 'keydown';
  //       /*
  //        * Cette partie constitue le code exécuté quand
  //        * Ctrl (17), Alt (18) et E (69)
  //        * sont enfoncées.
  //        */
  //       if (keys[17] && keys[18] && keys[70]) {
  //           // console.log('Ctrl + Alt + f');
  //           keys = {};
  //           var formulaire = document.getElementById("formulaire");
  //           // var formulaire = document.getElementsByClassName("container-formulaire");
  //           if (formulaire.style.display == "none"){
  //             formulaire.style.display = "block";
  //           }else{
  //             formulaire.style.display = "none";
  //           }
  //       }
  //   }
  //   /*
  //    * Fonction de rétro-compatibilité pour
  //    * les navigateurs Internet Explorer.
  //    * Elle marchera dans tous les navigateurs
  //    * et demandera qui s'abonne, à quel évènement
  //    * et ce qu'il se passe quand l'évènement est
  //    * appelé / levé.
  //    */
  //   function addEvent(element, event, func) {
  //       /*
  //        * Avons nous à faire à un vieil Internet Explorer ?
  //        */
  //       if (element.attachEvent) {
  //           /*
  //            * Abonnons nous alors comme Internet Explorer le propose.
  //            */
  //           return element.attachEvent('on' + event, func);
  //       } else {
  //           /*
  //            * Nous nous abonnons comme la spécification ECMAScript le propose.
  //            */
  //           return element.addEventListener(event, func, false);
  //       }
  //   }
  //   /*
  //    * Appel de la fonction de rétro-compatibilité
  //    * que nous venons de créer pour abonner `window`
  //    * au évènement `keydown` et `keyup`
  //    */
  //   addEvent(window, "keydown", trackMultipleKeyStroke);
  //   // addEvent(window, "keyup", trackMultipleKeyStroke);
  // },
  methods: {
   onSubmit: function () {
     this.v.form.$touch();
     if(this.v.form.$error) {
       // return
       // alert(this.v.form.$error)
     }
     // if(this.v.form.nom.$invalid){
     //   document.getElementById("erreurnom").className = "error";
     //   document.getElementById("erreurnom").innerHTML = this.v.form.nom.$error;
     //   // document.getElementById("nom").style.textDecoration = "none !important"
     // }else{
     //   document.getElementById("erreurnom").className = "errorcache";
     //   document.getElementById("erreurnom").innerHTML = "";
     // }

     // if(this.v.form.telephone.$error){
       // alert(this.v.form.telephone.$error)
     // }
     // to form submit after this
     // alert('Form submitted')
     if(!this.v.form.$invalid){
        // alert("nom = "+this.form.nom+" email = "+this.form.email+" message = "+this.form.message);
        // this.form.nom='';
        // this.form.email='';
        // this.form.message='';
        // this.form.rgpd= false;
        // this.v.$reset();
        // alert("Message envoyé.\n\r Nous vous répondrons au plus vite.");
        // alert("nom = "+this.form.nom+" email = "+this.form.email+" message = "+this.form.message);


       const config = {
         responseType: 'text',
       };
       const messageHtml = '<!DOCTYPE html>'+
                           '<html lang="fr">'+
                           '<head>'+
                             '<meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1">'+
                             '<title>Formulaire de contact Duo Danse.</title>'+
                           '</head>'+
                           '<body>'+
                             '<div style="width: 640px; font-family: Arial, Helvetica, sans-serif; border: 1px solid black; padding: 5px">'+
                               '<div style="font-size: 20px; text-align: center"> Formulaire de contact <br>du site Duo Danse.</div>'+
                               '<div style="font-size: 14px;">'+
                               '<p>Nom : '+this.form.nom+'</p>'+
                                 '<p>Mail : '+this.form.email+'</p>'+
                                 '<p>Message :</p>'+
                                 '<p>'+this.form.message+'</p>'+
                               '</div>'+
                             '</div>'+
                           '</body>'+
                           '</html>';
      // alert(messageHtml);
       axios
        .post(
            '../php/envoimailsmtp.php',
            {
                Sujet: "Formulaire de contact Duo Danse.",
                // envoi du nom d'un fichier contenant le script Html.
                // msgHTML: "contenuHtmlMail.html",
                // Envoi d'une chaîne Html précédement constituée.
                msgHTML: messageHtml,
                //Envoi d'une chaîne en texte brut précédement constituée.
                msgTxtBrut: "Formulaire de contact Duo Danse. "+this.form.nom+" a envoyé le message suivant : "+this.form.message+" La réponse devra se faire à cette adresse : "+this.form.email+".",
                // Liste des adresses mail de déstination séparées d'une virgule.
                // listeDestinataires: "duodanse33@gmail.com,duodanse@yahoo.fr",
                listeDestinataires: "duodanse33@gmail.com",
            },
            config,
        )
        .then(response => {
            // console.log('success', response.data.message);
            if (!response.data.error){
              this.form.nom='';
              this.form.email='';
              this.form.message='';
              this.form.rgpd= false;
              this.v.$reset();
              alert("Message envoyé.\n\rNous vous répondrons au plus vite.");
            }
        })
        .catch(error => {
            console.log(error.response);
        });
      }
   },
   controlechamp () {
    // alert("Changement : "+this.form.nom);
    if(this.form.nom == "admin"){
    //   alert("admin = : "+this.form.nom);
      this.form.nom="";
      // this.v.form.nom="";
      this.basculeModaleActualite();
    }
    // if(this.v.form.nom.$invalid){
    //   document.getElementById("nom").className = "erreur";
    // }
  },
    basculeModaleActualite() {
      // console.log("ctrl+atlt+a" + this.afficheModaleActualite);
      this.afficheModaleActualite = !this.afficheModaleActualite;
      // console.log(this.afficheModaleActualite);
    },
  }
}
</script>

<style scoped>
.contacts{
  margin:2% auto 2% auto;
}
.row{
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  position: relative;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
  transform: translate3d(0, 0, 0);
	-moz-transform: none;
  box-sizing: border-box;
  background-position: 50% -4px;
  padding-bottom: 1%; /* ajouté quand le div de message d'erreur est oté. */
}
.section-contacts{
  display: flex;
  align-items: flex-start;
  -webkit-box-align: start;
	-moz-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
  background-attachment: fixed;
	max-width: 100% !important;
	width: 100% !important;
	padding: 6.5em 0 !important;
	margin: 0 !important;
  background: linear-gradient(330deg, rgba(2,0,36,1) 0%, rgba(14,14,212,1) 35%, rgba(0,212,255,1) 100%);
	background-size: cover;
}
.container-texte-contacts{
  width: 50%;
  max-width: 45em;
  top: 7.5rem;
  align-self: flex-start;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  float: none;
  margin: 0;
  /* position: sticky !important; */
  padding: 20px !important;
}
.block-text{
  margin: .5em auto 1.5em;
  padding: 0;
  opacity: 1;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  cursor: default;
  font-size: 16px;
}
h1{
  font-size: 1.1em;
  padding-bottom: 1.1em;
  text-align: center;
}
h3{
  font-size: 3em;
}
blockquote, dd, div, dl, dt, form, h2, h3, h4, h5, h6, li, ol, p, pre, td, th, ul {
    margin: 0;
    padding: 0;
    direction: ltr;
}
#block-text-contacts p{
  font-size: 1.5rem;
  font-family: "Open Sans", sans-serif;
  color: #000;
  line-height: 1.2;
  text-rendering: optimizeLegibility;
  margin-bottom: 1.25rem;
  text-align: left;
}
blockquote{
  text-align: center;
  quotes: "\201C""\201D";
  padding: 1.5rem;
  border: 0;
  line-height: 1.6rem;
  text-align: center;
  margin: 2rem 0;
  position: relative;
  direction: ltr;
}
blockquote p {
    font-size: 1.9rem;
    font-family: "Great Vibes", cursive;
    line-height: 140%;
    font-weight: 400;
    display: inline;

}
blockquote::after, blockquote::before {
    color: transparent;
    background: 0 0;
    content: "";
    width: 2rem;
    height: 2rem;
    border: solid #934239;
    position: absolute;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
blockquote::before {
    top: 0;
    left: 0;
    bottom: auto;
    right: auto;
    font-size: 4rem;
    line-height: 0.1rem;
    margin-right: 0.1rem;
    border-width: 1px 0 0 1px;
}
blockquote::after {
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;
    font-size: 4rem;
    line-height: 0.1rem;
    margin-right: 0.1rem;
    border-width: 0 1px 1px 0;
}
h2{
  position: relative;
  color: #fc7100;
  color: rgba(0, 135, 206, 0.9);
  background: 0 0;
  font-size: 1.9rem;
  font-style: normal;
  text-align: left;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
}
h2::before {
    content: "";
    width: 90px;
    height: 2px;
    position: absolute;
    bottom: -8px;
    /* background-image: linear-gradient(to right, #fc7100, #934239); */
    background-image: linear-gradient(to right, rgba(0, 135, 206, 0.9), rgba(0, 70, 207, 0.9));
}
/* .animation-scroll{
  transition: -webkit-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              -moz-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              -o-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              opacity 1.2s cubic-bezier(0.4, 0.1, 0.2, 1) !important;
} */
.bloc-carte{
  /* padding-bottom: 44%; */
}
.carte-renney{
  width: 90%;
  height: 25vw;
  border:0;
}

/* Pour les besoins en programmation.
A retirer une fois operationnel.
ctrl+alt+f pour faire apparaitre et disparaitre le formulaire. */
/* #formulaire{
  display: none;
} */
.container-formulaire{
  /* display: none; */
  /* display: flex; */
  position: relative;
  width: 50%;
  max-width: 45em;
  float: none;
  margin: auto;
  padding: 2em;
  border-radius: 18px;
  overflow: hidden; /*Garde l'image à l'interieur du container.*/
  opacity: 1;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}

.bloc-formulaire{
  max-width: inherit;
  /* border: 1px red solid; */
  margin: 0 auto;
  padding: 20px 0;
  border-radius: 18px;
  overflow: hidden; /*Garde l'image à l'interieur du container.*/
  opacity: 1;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}
.block-form{
  border-radius: 8px !important;
  padding: 0;
  max-width: 38em !important;
  background-color: transparent !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  margin: 0 0 0 auto !important;
  /* --gridtemplate-padding: 0.25rem !important; */

}
form{
  margin: 0 auto !important;
  max-width: none;
  padding: 0;
  direction: ltr;
}
/************************************/
.columns {
  display: flex;
  flex-direction: column;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
    margin: 0;
    padding: 0px 10px 0px 10px;
    width: 95%;
}
label:not([class="politiqueConfidentialiteCheckLabel"]){
  /* visibility: hidden; */
  display: none;
}
input[type="text"],
input[type="email"] {
    border: 2px solid blue;
    background: 0 0;
    background-color: #fff;
    color: rgba(0,0,0,.75);
    border-radius: 8px !important;
    font-family: inherit;
    font-size: .875rem;
    font-style: normal;
    text-decoration:none;
    margin: 0 0 0 0;
    padding: .5rem;
}
.politiqueConfidentialiteCheck{
    border: 2px solid blue;
    background-color: #fff;
    color: rgba(0,0,0,.75);
    height: 1.5rem !important;
    width: 1.5rem;
    border-radius: 8px;
    margin: .5em 0 0 0;
}
.erreur{
  -webkit-appearance: none;
  border-radius: 8px !important;
  border: 3px solid red !important;
}
input[type="submit"]{
   margin: 1rem;
}
textarea {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 2px solid blue;
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    display: block;
    font-size: .875rem;
    margin: 0 0 0 0;
    padding: .5rem;
    height: 10rem;
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: -webkit-box-shadow .45s,border-color .45s ease-in-out;
    -moz-transition: -moz-box-shadow .45s,border-color .45s ease-in-out;
    transition: box-shadow .45s,border-color .45s ease-in-out;
}
.enligne{
  display: flex;
  flex-direction: row;
  padding: 1em 1em 0 0;
}
/* input[type=checkbox] {
    -border-radius: 4px;
    height: 20px;
    width: 15px;
    background: #fff;
    border: 1px solid #ccc;
} */
a, .rgpdLink{
  color: rgb(79, 235, 230);
}
.politiqueConfidentialiteCheckLabel{
  font-size: .75rem;
  padding: 0 1em 0 1em;
  color: white;
  width: 60%;
}
.error {
    display: flex;
    /* height: 1rem; */
    /* color: #fff; */
    /* padding: .375rem .5625rem; */
    margin: 1px 0 1px 0;
    /* font-size: .75rem;
    font-weight: 400;
    font-style: italic; */
    /* background: #c60f13; */
    width: fit-content;
}
.errorcache {
    display: flex;
    /* height: 1rem; */
    /* content: ''; */
}
.buttonsubmit {
    background-color: #00a2fc;
    color: #FFFFFF;
    font-family: 'Open Sans',sans-serif;
    font-size: 1.125rem;
    width: 10rem;
    -webkit-transition: color .3s cubic-bezier(.4,0,.2,1),background .3s cubic-bezier(.4,0,.2,1),-webkit-box-shadow .3s cubic-bezier(.4,0,.2,1),border .3s cubic-bezier(.4,0,.2,1);
    -moz-transition: color .3s cubic-bezier(.4,0,.2,1),background .3s cubic-bezier(.4,0,.2,1),-moz-box-shadow .3s cubic-bezier(.4,0,.2,1),border .3s cubic-bezier(.4,0,.2,1);
    -o-transition: color .3s cubic-bezier(.4,0,.2,1),background .3s cubic-bezier(.4,0,.2,1),box-shadow .3s cubic-bezier(.4,0,.2,1),border .3s cubic-bezier(.4,0,.2,1);
    transition: color .3s cubic-bezier(.4,0,.2,1),background .3s cubic-bezier(.4,0,.2,1),box-shadow .3s cubic-bezier(.4,0,.2,1),border .3s cubic-bezier(.4,0,.2,1);
    border-radius: 40px !important;
    line-height: 1;
    padding: .8rem 1.8rem;
    text-align: center;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.16);
    -moz-box-shadow: 0 2px 2px 0 rgba(0,0,0,.16);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.16);
}
.buttonsubmit:hover {
    background: transparent;
    color: white;
}
.msgPolitiqueConfidentialite{
  display: none;
  text-align: left;
  color: white;
}
/************************************/
@media only screen and (max-width: 480px) { /*Mobile*/
  .container-texte-contacts{
    width: 95%;
  }
  .container-formulaire{
    width: 95%;
  }
  .bloc-carte{
    padding-bottom: 0;
  }
  .carte-renney{
    width: 90%;
    height: 50vw;
    border:0;
  }
}
</style>
