<template>
<div id="app">
  <div id="header">
    <div class="header-block">
      <img class="duodanselogo" src="images/galerie-duodanse/EnTeteDuoDanse.png"/>
    </div>
  </div>
  <div id="content">
    <masonry-wall :items="images" :ssr-columns="1" :column-width="300" :gap="16">
      <template #default="{ item, id }">
        <div class="masonry-item" :style="{ height: `${id}px` }" >
          <img :src="item.image" />
          <!-- <img src="images/galerie-duodanse/PetitesImages/Affiche_Cloture_2022.jpg"/> -->
          <div class="overlay" @click="openModal(item.srcBig)">
          <!-- <div class=""> -->
            <div class="img-info">{{ item.description }}</div>
            <div class="title-info">{{ item.title }}</div>
            
          </div>
        </div>
      </template>
    </masonry-wall>
    <div v-if="selectedImage" class="modal" @click="closeModal">
      <img :src="selectedImage" />
    </div>
  </div>
</div>
</template>

<script>
import MasonryWall from '@yeger/vue-masonry-wall'
// import { MasonryWall } from '@yeger/vue-masonry-wall'
// import images from '/json/ImagesGalerieDuodanse.json'

export default {
  components: {
    MasonryWall,
  },
  data() {
    return {
      images: [],
      selectedImage: null
    }
  }, 
  methods: {
    openModal(image) {
      this.selectedImage = image;
    },
    closeModal() {
      this.selectedImage = null;
    },
    donneesimages() {
      fetch('/json/ImagesGalerieDuodanse.json')
      // fetch('/images/galerie-duodanse/PetitesImages')
      .then(response => response.json())
      .then(data => {
        this.images=this.shuffleArray(data);
        // console.log(data);
      })
      // .then(data => {
      //   this.images=data;
      //   // console.log(data);
      // })
      .catch(console.error);
      // console.log(this.images)
    },
    // Mélange les éléments d'un tableau de manière aléatoire.
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      // console.log(array);
      return array;
    }
  },
  created() {
    this.donneesimages();
  }

};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
a {
  color: #000;
  text-decoration: none;
}
a:active {
  color: #000;
}
html, body, #app {
  height: 100%;
  background-color: #f5f6f7;
}
html .resize, body .resize, #app .resize, html .resize-hover, body .resize-hover, #app .resize-hover {
  display: block;
  position: fixed;
  right: 5px;
  bottom: 3px;
  width: 46px;
  height: 45px;
  background: transparent;
  transition: all 0.4s ease-out;
}
html .resize, body .resize, #app .resize {
  opacity: 1;
}
html .resize-hover, body .resize-hover, #app .resize-hover {
  opacity: 0;
}
html .resize-hover:hover, body .resize-hover:hover, #app .resize-hover:hover {
  opacity: 1;
}
#app {
  position: relative;
}
#app #header {
  height: 80px;
  display: block;
  background: #e8e8e8;
  background: #ececec;
  background: linear-gradient(0deg, #ececec 0%, #d2d2d2 94%, #d2d2d2 100%);
  color: #333333;
  line-height: normal;
  font-size: 16px;
  /* position: sticky; */
  z-index: 999;
  width: 100%;
  box-shadow: 0 -3px 8px 2px rgba(0, 0, 0, 0.6);
  border-bottom: solid 1px #4e4944;
}
#app #header .header-block {
  max-width: 1380px;
  margin: 0 auto;
  height: 100%;
}
#app #header .header-block .duodanselogo {
  float: left;
  height: 70px;
  padding-top: 10px;
  transition: all 0.4s ease-out;
}
#app #header .header-block .duodanselogo:hover {
  filter: grayscale(1);
}
#app #content {
  /* position: absolute; */
  /* top: 90px; */
  /* bottom: 0; */
  /* width: 98%; */
  padding: 0.8em;
  overflow-y: auto;
}
#app #content .masonry-item {
  position: relative;
  margin-bottom: 10px;
}
#app #content .masonry-item img {
  width: 100%;
  /* display: block; */
  box-shadow: 5px 5px 8px 2px rgba(0, 0, 0, 0.6);
  border-radius: 15px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  /* background: rgba(0, 0, 0, 0.4); Optional: for better visibility of text */
}
#app #content .img-info {
  font-family: Arial, Helvetica, sans-serif;
  /* position: absolute; */
  overflow: hidden;
  top: 0;
  left: 0;
  width: 97.8%;
  height: 100%;
  text-align: center;
  opacity: 0;
  transition: all 0.2s ease-out;
  transition-delay: 0.05s;
}
#app #content .img-info p {
  cursor: text;
}

#app #content .title-info {
  position: absolute;
  bottom: .5em;
  left: 0;
  width: 97.8%;
  height: 10%;
  text-align: right;
  margin-right: 1em;
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* color: red; */
}
#app #content .img-info .title-info, #app #content .img-info .desc-info {
  padding: 10px 4px;
  position: absolute;
  transition: all 0.3s ease-out;
  transition-delay: 0.1s;
  background-color: rgba(255, 255, 255, 0.8);
  width: calc(100% - 20px);
  margin-left: 10px;
}
#app #content .img-info .title-info {
  top: -10px;
  color: #676464;
  text-rendering: geometricPrecision;
  display: block;
  box-shadow: 0px 4px 4px -4px rgba(0, 0, 3, 0.7);
}
#app #content .img-info .desc-info {
  bottom: -15px;
  font-size: 12px;
  color: #5f5f5f;
  display: block;
  box-shadow: 0px -4px 6px -5px rgba(0, 0, 3, 0.75);
}
#app #content .img-info:hover {
  opacity: 1;
}
#app #content .img-info:hover .title-info {
  top: 10px;
}
#app #content .img-info:hover .desc-info {
  bottom: 10px;
}
#app {
  overflow: auto;
  position: relative;
}
#app .some-info {
  line-height: 1.6;
  text-align: center;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal img {
  max-width: 100%;
  max-height: 100%;
  border-color: white;
  border-width: 3px;
  border-style:solid;
  border-radius: 20px;
  box-shadow:  8px 8px 8px 2px rgba(253, 252, 252, 0.6);
}

#footer {
  padding: 10px 0 20px;
  width: 100%;
  text-align: center;
  position: relative;
  transition: all 0.4s ease-out;
}
#footer p.copyright {
  width: 108px;
  margin: 0 auto;
  color: #adadad;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}
@media only screen and (max-width: 1399px) {
  body #app #header .header-block {
    max-width: 1040px;
  }
}
@media only screen and (max-width: 1049px) {
  body #app #header .header-block {
    max-width: 680px;
  }
}
@media only screen and (max-width: 699px) {
  body #app #header .header-block {
    max-width: 100%;
    margin: 0 30px;
  }
  /* body #app .lightbox-alpha .btn-close {
    top: 20px;
    right: 10px;
  } */
  /* body #app .lightbox-alpha .lightbox-content {
    top: 30px;
    transform: translate(-50%, 0);
    width: 90%;
  } */
  /* body #app .lightbox-alpha .lightbox-content img {
    width: 100%;
  } */
}
@media only screen and (max-width: 444px) {
  body #app #header .header-block img.npm-code {
    position: absolute;
    right: 15px;
  }
  /* body #app #content .lightbox-alpha .btn-close {
    right: 5px;
  } */
}
</style>
