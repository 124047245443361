<template>
<div id="Videos" class="videos">
  <!-- !-- Bloc de renseignements stylés 4 avec vidéos. -->
  <div id="section-videos" class="row section-videos">
      <div id="texte-videos" ref="texte-videos" class="container-texte-videos">
          <div id="liste" ref="liste" class="block-text liste-videos">
              <ul>
                  <!-- Si vous souhaitez basculer la classe d'éléments individuels par programme par VueJS
                  VueJS vous permet de lier la classe de la balise d'ancrage (par exemple) directement à
                  l'index de l'élément li de sorte que lorsque la variable vuejs liée à l'index change,
                  la classe change également. Consultez ces deux liens pour plus de détails
                  C'est le nœud de la solution
                  :class="{nomdelaclasse:index == current}
                  disponible sur le lien ci-dessous et un autre article qui explique au format blog comment
                  la classe d'ancrage peut être contrôlée dynamiquement dans vuejs
                  https://jsfiddle.net/Herteby/kpkcfcdw/
                  https://stackoverblow.wordpress.com/2021/04/03/how-modern-javascript-makes-click-simulation/ -->
                  <li v-bind:class="{activeItem:index == current}" v-for="(item, index) in playlist" :key="item.titre" v-on:click="changevideo(item.titre, item.src, item.type, index);">
                    {{ item.titre }}
                  </li>
              </ul>
          </div>
      </div>
      <div id="lecteur-video" class="container-lecteur-video">
          <div id="lecteur_bloc" class="bloc-lecteur">
              <!-- L'ajout de l' attribut key avec l'URL de la source à l'élément vidéo entraînera la mise à jour de la vidéo et de la source lorsque l'URL change. -->
              <!-- Cela prend en charge le remplacement dynamique d'une vidéo + source. -->
              <!-- <video controls :key="this.srcselectionne" id="blocvideo" ref="blocvideo" poster="https://www.duodanse33.fr/videos/poster/logovideo.png"> -->
              <video controls id="blocvideo" ref="blocvideo" poster="videos/poster/logovideo.png">
                  <source id="src-bloc-video" :src="this.srcselectionne" :type="this.typeselectionne" />
              </video>
          </div>
      </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Videos_Vue',
  components: {
  },
  data () {
    return {
      // playlist: [
      //           {titre: 'Présentation Duo Danse.', src:'https://www.duodanse33.fr/videos/Presentation Duo Danse 2022_720.mp4', type:'video/mp4'},
      //           {titre: 'DUO WEEK END DANCE 2019.', src:'https://www.duodanse33.fr/videos/DWED 2022.mp4', type:'video/mp4'},
                // {titre: 'Dansons sur les quais 2017.', src:'https://www.duodanse33.fr/videos/DSLQ2017/DSLQ2017.mp4', type:'video/mp4'},
                // {titre: 'SAISON 2016-2017.', src:'https://www.duodanse33.fr/videos/Bilan2017/Bilan2017DuoDanseEleves.mp4', type:'video/mp4'},
                // {titre: 'SOIREE DE CLÔTURE 2017.', src:'https://www.duodanse33.fr/videos/Cloture2017/Cloture2017.mp4', type:'video/mp4'},
                // {titre: 'DUO WEEK END DANCE 2017.', src:'https://www.duodanse33.fr/videos/DWED2017/DuoWeekEndDance2017.mp4', type:'video/mp4'},
                // {titre: '10ans de l\'association Hand To Hand.', src:'https://www.duodanse33.fr/videos/HandToHand10A/HandToHand10_Valse.mp4', type:'video/mp4'},
                // {titre: 'Soirée de Noël 2016.', src:'https://www.duodanse33.fr/videos/Soirees/Fongravey_171216/DanseLigne.mp4', type:'video/mp4'}
                // ],
      // playlist: list,
      playlist: [],
      srcselectionne: null,
      typeselectionne: null,
      typevideo: null,
      current: 0,
      // classitem: String,
    }
  },
  methods: {
    changevideo: function (titrevideo, sourcevideo, typevideo, index) {
      this.srcselectionne=sourcevideo;
      this.typeselectionne=typevideo;
      this.current = index;
      this.$refs.blocvideo.load();
      this.$refs.blocvideo.play();
    },
    donneesplaylist() {
      // console.log("Dans donneesplaylist")
      fetch('json/playlistvideos.json')
      .then(response => response.json())
      .then(data => {
        this.playlist=data
        this.srcselectionne=this.playlist[0].src;
        this.typeselectionne=this.playlist[0].type;
        this.current = 0;
        this.$refs.blocvideo.load();
      })
      .catch(console.error);
    }
  },
  mounted: function () {
    this.donneesplaylist();
  }
}
</script>

<style scoped>
.videos{
  margin:2% auto 2% auto;
}
video{
  /* width:480px;
  height: 320px; */
  width:100%;
  height: auto;
}
.row{
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -moz-transform: none;
  transform: none;
  width: auto;
  padding: 0;
  margin: 0 auto;
  max-width: 90em;
  -moz-box-pack: center;
}
.section-videos{
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  box-sizing: border-box;
  float: none;
  position: relative;
  line-height: 16px;
  /* z-index: auto; */
  /* height: 100vh; */
  /* maxwidth: 90em; */
  /* 1440x485.5 0 0*/
  /* border: 2px black solid; */
}
.card{
  border: 2px black solid;
}
.container-texte-videos{
  display: block;
  box-sizing: border-box;
  float:none;
  line-height: 16px;
  position:relative;
  /* z-index: 2; */
  box-shadow: 12px 7px 35px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding:16px;
  width:40%;
  /* height: 100vh; */
  /* maxwidth: 90em; */
}
.block-text{
  margin: .5em auto 1.5em;
  padding: 0;
  opacity: 1;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  cursor: default;
  font-size: 16px;
}
blockquote, dd, div, dl, dt, form, h1, h2, h3, h4, h5, h6, li, ol, p, pre, td, th, ul {
    margin: 0;
    padding: 0;
    direction: ltr;
}
p{
  font-size: 1.008rem;
  font-family: "Open Sans", sans-serif;
  color: #000;
  line-height: 1.2;
  text-rendering: optimizeLegibility;
  margin-bottom: 1.25rem;
  text-align: left;
}
blockquote{
  text-align: center;
  quotes: "\201C""\201D";
  padding: 1.5rem;
  border: 0;
  line-height: 1.6rem;
  text-align: center;
  margin: 2rem 0;
  position: relative;
  direction: ltr;
}
blockquote p {
    font-size: 1.9rem;
    font-family: "Great Vibes", cursive;
    line-height: 140%;
    font-weight: 400;
    display: inline;

}
blockquote::after, blockquote::before {
    color: transparent;
    background: 0 0;
    content: "";
    width: 2rem;
    height: 2rem;
    border: solid #934239;
    position: absolute;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
blockquote::before {
    top: 0;
    left: 0;
    bottom: auto;
    right: auto;
    font-size: 4rem;
    line-height: 0.1rem;
    margin-right: 0.1rem;
    border-width: 1px 0 0 1px;
}
blockquote::after {
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;
    font-size: 4rem;
    line-height: 0.1rem;
    margin-right: 0.1rem;
    border-width: 0 1px 1px 0;
}
h2{
  position: relative;
  color: #fc7100;
  color: rgba(0, 135, 206, 0.9);
  background: 0 0;
  font-size: 1.9rem;
  font-style: normal;
  text-align: left;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
}
h2::before {
    content: "";
    width: 90px;
    height: 2px;
    position: absolute;
    bottom: -8px;
    background-image: linear-gradient(to right, #fc7100, #934239);
    background-image: linear-gradient(to right, rgba(0, 135, 206, 0.9), rgba(0, 70, 207, 0.9));
}
/* .animation-scroll{
  transition: -webkit-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              -moz-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              -o-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              opacity 1.2s cubic-bezier(0.4, 0.1, 0.2, 1) !important;
} */
.container-lecteur-video{
  width: 55%;
  /* max-width: 26.249994em; */
  margin: 0;
  padding: 16px;
  float: none;
  position: relative;
  right: 4.16667%;
  left: auto;
}

.bloc-lecteur{
  max-width: inherit;
  /* border: 1px red solid; */
  margin: 0 auto;
  padding: 0;
  border-radius: 8px;
  overflow: hidden; /*Garde l'image à l'interieur du container.*/
  opacity: 1;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}
a{
  transition: color 0.3s cubic-bezier(0.4, 0.1, 0.2, 1), background 0.3s cubic-bezier(0.4, 0.1, 0.2, 1);
  text-decoration: none;
  line-height: inherit;
}
img{
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
a img{
  border: none;
}
.bloc-lecteur video {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.3s ease, -webkit-box-shadow 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease, -moz-box-shadow 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease, box-shadow 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease, -moz-transform 0.3s ease, -o-transform 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease-in-out;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  -webkit-filter: brightness(1);
  -moz-filter: brightness(1);
  -ms-filter: brightness(1);
  filter: brightness(1);
  background: #fff;
  min-width: 100%;

}
.liste-videos ul{
  list-style: none;
  padding: 0 10% 0 10%;
}
.liste-videos li{
  margin: .3em;
  /* padding-left: 1em; */
  padding: .5em 1em .5em 1em;
  background-color: rgba(0, 135, 206, 0.5);
  text-decoration: none;
  text-align: left;
  border-radius: 10px;

}
.liste-videos li:hover{
  background-color: rgba(0, 135, 206, 0.8);
  transform:scale(1.01);
  box-shadow:1px 1px 3px rgba(0,0,0,0.5);
  transition:all 0s, background 0.2s;
  /* z-index:10; */
}
.activeItem{
  background-color: rgba(0, 135, 206, 0.8) !important;
}
@media only screen and (max-width: 480px) { /*Mobile*/
  .section-videos{
    flex-direction: column-reverse;
    flex-direction: column;
    -webkit-box-direction: normal-reverse;
    -moz-box-direction: normal-reverse;
    -webkit-box-orient: vertical-reverse;
    -moz-box-orient: vertical-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
  }
  .container-texte-videos{
    width: 98%;
  }
  .liste-videos ul{
    padding: 0;
  }
  .container-lecteur-video{
    width: 98%;
    padding: 0;
    right: 0;

  }
  .videos{
    display: none;
  }
  video{
    width:95%;
    height: auto;
  }

}
</style>
