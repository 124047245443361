<template>
  <div id ="Accueil" class="accueil">
    <div class="">
        <div class="block-title"><h1>Cours de danse - Blanquefort</h1></div>
    </div>
    <div id="section-accueil" class="row section-accueil">
      <div id="texte-accueil" class="container-texte-accueil">
        <div id="text_Accueil-titre" class=" text-titre animation-scroll">
            <h2>DUO DANSE</h2>
        </div>
        <div id="text_Accueil-1" class="block-text">
            <p>
              L'association DUO DANSE est née de l'envie d'amoureux des danses à deux de faire partager leur passion.<br><br>
              Vous y trouverez une équipe à l'écoute, des professeurs attentifs à vos besoins, des cours adaptés à votre niveau,
              et surtout une ambiance toujours conviviale pour vous immerger dans les Danses de Salon et le Rock.<br><br>
              Emmené par Audrey Bourgeois notre professeur diplomée, venez découvrir,
              vous initier, progresser, vous perfectionner et surtout vous amuser dans nos cours,
              nos stages, nos soirées et toutes nos manifestations.
            </p>
        </div>
        <div id="text_Accueil-2" class="block-text">
            <blockquote>
                <p>Nous vous attendons avec plaisir et impatience !!!!!</p>
            </blockquote>
        </div>
      </div>
      <!-- Bloc de renseignements simple (fond bleu). -->
      <div id="Actualite" class="section-actualite">
        <div class="blocinfo">
          <div class="cellTitreInfo">
            <h1>ACTUALITE.</h1>
            <p>
              <b><i>Cliquez sur les dates pour plus d'informations.</i></b>
            </p>
          </div>
          <div class="cellInfo" v-for="actualite in actualite" :key="actualite.id">
            <div class="cellDate Date">
              <a :href="actualite.lien">
                  <p class="Jour">{{actualite.jour}}</p>
                  <p class="Mois">{{actualite.mois}}</p>
              </a>
            </div>
            <div ref="InfoDate" class="cellInfoDate texteannonce" v-html="actualite.annonce">
            </div>
            <div v-if="actualite.id != '5'" class="separationactualite"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import axios from 'axios'

  export default {
    name: 'Actualite_Vue',
    data() {
      return {
        imgsArr: [],
        actualite: {},
        group: 0
      };
    },
    methods: {
      donneesactualite() {
        // axios.get('json/loadimages-duodanse.json?group=' + this.group)
        // .then(res => {
        //   this.group++;
        //   console.log(this.group)
        //   console.log("res.data = " + res.data)
        //   // this.imgsArr = this.imgsArr.concat(res.data);
        //   this.imgsArr = this.shuffle(this.imgsArr.concat(res.data));
        //   console.log("this.imgsArr = "+this.imgsArr)
        // });

        fetch('json/itemsactualite.json')
        .then(response => response.json())
        .then(data => {
          this.actualite=data
          // console.log(this.actualite)
        })
        .catch(console.error);
        // axios.get('json/itemsactualite.json')
        // .then(({data}) => {
        //   this.actualite = data
        //   console.log("this.actualite = "+this.actualite);
        // })
        // .catch(console.error);
      }
    },
    created() {
      this.donneesactualite();
    }
  };
</script>

<style scoped>
  .accueil{
    padding:2% 5%;
  }
  .row{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -moz-transform: none;
    transform: none;
    width: auto;
    padding: 0;
    margin: 0 auto;
    max-width: 90em;
  }
  .section-accueil{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: row;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    align-items: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    box-sizing: border-box;
    float: none;
    position: relative;
    line-height: 16px;
  }
  .container-texte-accueil{
    display: block;
    box-sizing: border-box;
    float:none;
    line-height: 16px;
    position:relative;
    z-index: 2;
    box-shadow: 12px 7px 35px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding:16px;
    width:40%;
  }
  .block-text{
    margin: .5em auto 1.5em;
    padding: 0;
    opacity: 1;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    cursor: default;
    font-size: 1em;
  }
  .section-actualite{
    width: 55%;
    /* max-width: 26.249994em; */
    margin: 0;
    padding: 10px;
    float: none;
    position: relative;
    right: 3%;
    left: auto;
    box-shadow: 12px 17px 15px 0 rgba(0, 0, 0, 0.2);
  }
  .blocinfo{
    background-color: rgba(0, 135, 206, 0.7);
    border-style: solid;
    border-width: 5px;
    border-radius: 10px;
    border-color: rgba(0, 135, 206, 0.9);
  }
  .cellInfo{
    display: grid;
    grid-template-columns:auto 1fr;
    grid-auto-rows: auto auto;
    grid-column-gap: 2%;
    grid-row-gap: 20px;
    padding-top: 1em;
  }
  .cellTitreInfo{
    grid-column: 1 / 3;
    text-align: center;
    color: blue;
    padding-top: 1em;
    height:auto;
  }
  .cellTitreInfo p{
    color: red;
    font-family: 'Raleway', sans-serif;
    font-size: 10px;
    line-height: 10px;
    padding: 2px;
    margin: 0px;
  }
  .cellDate{
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: column;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    height: 70px;
    width: 80px;
    text-align: center;
    border-style: solid;
    border-width: 4px;
    border-radius: 4px;
    border-color: rgba(195, 150, 198, 0.87);
    background-color: rgba(0,0,0,0.5);
    margin-left: 2vw;
  }
  .cellInfoDate{
    /* grid-column: 2 / 3; */
    color: rgba(46, 46, 46, 1);
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 1em;
    text-align: left;
    padding-right: 2%;
  }
  .separationactualite{
    grid-column: 1 / 3;
    border:1px solid white;
    margin: 0 3% 0 3%;
    padding: 0;
  }
  .cellDate:hover{
    border-color: white;
  }
  .Jour {
    color: white;
    font-weight: 600;
    font-size: .9em;
    padding-top: 15%;
  }
  .Mois {
    color: red;
    font-weight: 600;
    font-size: 12px;
    margin-top: 15%;
  }
  .block-title {
    font-family: Montserrat, sans-serif;
    color: white;
    font-weight: 500;
    background-color: rgba(0, 135, 206, 0.9);
    font-size: 1.2vw;
    line-height: 1.4;
  }
  blockquote, dd, div, dl, dt, form, h1, h2, h3, h4, h5, h6, li, ol, p, pre, td, th, ul {
    margin: 0;
    padding: 0;
    direction: ltr;
  }
  blockquote{
    text-align: center;
    quotes: "\201C""\201D";
    padding: 1.5rem;
    border: 0;
    line-height: 1.6rem;
    text-align: center;
    margin: 2rem 0;
    position: relative;
    direction: ltr;
  }
  blockquote p {
    font-size: 1.5rem;
    font-family: "Great Vibes", cursive;
    line-height: 140%;
    font-weight: 400;
    display: inline;
  }
  blockquote::after, blockquote::before {
    color: transparent;
    background: 0 0;
    content: "";
    width: 2rem;
    height: 2rem;
    border: solid #934239;
    position: absolute;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  blockquote::before {
    top: 0;
    left: 0;
    bottom: auto;
    right: auto;
    font-size: 4rem;
    line-height: 0.1rem;
    margin-right: 0.1rem;
    border-width: 1px 0 0 1px;
  }
  blockquote::after {
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;
    font-size: 4rem;
    line-height: 0.1rem;
    margin-right: 0.1rem;
    border-width: 0 1px 1px 0;
  }
  h2{
    position: relative;
    color: #fc7100;
    color: rgba(0, 135, 206, 0.9);
    background: 0 0;
    font-size: 1.9em;
    font-style: normal;
    text-align: left;
    font-family: Montserrat, sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1.1;
    text-rendering: optimizeLegibility;
  }
  h2::before {
    content: "";
    width: 90px;
    height: 2px;
    position: absolute;
    bottom: -8px;
    background-image: linear-gradient(to right, #fc7100, #934239);
    background-image: linear-gradient(to right, rgba(0, 135, 206, 0.9), rgba(0, 70, 207, 0.9));
  }
  @media only screen and (max-width: 480px) { /*Mobile*/
    .accueil{
      padding:2% 0;
    }
    .section-accueil{
      flex-direction: column;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
    }
    .container-texte-accueil{
      padding:5px;
      width:98%;
      /* height: 100vh; */
      /* maxwidth: 90em; */
    }
    .section-actualite{
      z-index: 3;
      padding:5px;
      width:100%;
      top: -2em;
      right: 0;
      /* height: 100vh; */
      /* maxwidth: 90em; */
    }
    h2{
      font-size: 1.5rem;
    }
    .container-image{
      width: 80%;
      right: 0;
      padding: 0 0 25px 0;
      top: -50px;
    }
    .texteannonce {
      font-size: .6rem;
    }
    .cellDate{
      height: 80px;
      width: 70px;
    }
  }
</style>
