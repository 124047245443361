<template>
<div id="Professeurs" class="professeurs">
  <!-- Bloc de renseignements stylés 1. -->
  <div id="section-professeurs" class="row section-profs">
      <div id="texte-profs" class="container-texte-profs">
          <div id="text_Profs-titre" class=" text-titre animation-scroll">
              <h2>AUDREY BOURGEOIS</h2>
          </div>
          <div id="text_Profs-1" class="block-text">
              <p>
                  De l'experience, de la pédagogie et une bonne humeur à toute épreuve.
              </p>
              <p >
                  - 3e place du Championnat de France en danses standards, Bordeaux, 1995<br>
                  - Championne d'Ile de France en danses standards, 1999<br>
                  - Vice-championne d'Ile de France en danses latines, 1999<br>
                  - Championne de France à Valence, 2000<br>
                  - Compagnie du Feeling Dance, Paris (chorégraphe Charly Moser), 2000-2006<br>
                  - Monitorat et Professorat, 2004<br>
                  - Professeur au Feeling Dance Studio (Paris), 2004-2006<br>
                  - Professeur dans l'association Jet7 Swing (Pessac), 2007-2016<br>
                  - Professeur dans l'association DUO DANSE (Blanquefort) depuis 2016
              </p>
          </div>
          <div id="text_Profs-2" class="block-text">
              <blockquote>
                  <p>Elle saura à coup sûr vous faire aimer la danse.</p>
              </blockquote>
          </div>
      </div>
      <!-- Image d'illustration du bloc de renseignements-->
      <div id="image" class="container-image">
          <figure id="img_bloc" class="bloc-image">
              <a href="/images/audrey_web.jpg" class="blk-image__link lightbox">
                  <img
                      src="/images/audrey_web.jpg"
                      data-src="/images/audrey_web.jpg"
                      alt="Cours de danse Blanquefort"
                      width="1276"
                      height="687"
                      class=""
                  />
              </a>
          </figure>
      </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Professeurs_Vue',
  components: {
  },
}
</script>

<style scoped>

.professeurs{
  margin:2% auto 2% auto;
}

.row{
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -moz-transform: none;
  transform: none;
  width: auto;
  padding: 0;
  margin: 0 auto;
  max-width: 90em;
}
.section-profs{
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: row;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  align-items: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  box-sizing: border-box;
  float: none;
  position: relative;
  line-height: 16px;
  /* z-index: auto; */
  /* height: 100vh; */
  /* maxwidth: 90em; */
  /* 1440x485.5 0 0*/
  /* border: 2px black solid; */
}
.container-texte-profs{
  display: block;
  box-sizing: border-box;
  float:none;
  line-height: 16px;
  position:relative;
  z-index: 2;
  box-shadow: 12px 7px 35px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding:16px;
  width:60%;
  /* height: 100vh; */
  /* maxwidth: 90em; */
}
.block-text{
  margin: .5em auto 1.5em;
  padding: 0;
  opacity: 1;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  cursor: default;
  font-size: 1em;
}
blockquote, dd, div, dl, dt, form, h1, h2, h3, h4, h5, h6, li, ol, p, pre, td, th, ul {
    margin: 0;
    padding: 0;
    direction: ltr;
}
p{
  font-size: 1.008rem;
  font-family: "Open Sans", sans-serif;
  color: #000;
  line-height: 1.2;
  text-rendering: optimizeLegibility;
  margin-bottom: 1.25rem;
  text-align: left;
}
blockquote{
  text-align: center;
  quotes: "\201C""\201D";
  padding: 1.5rem;
  border: 0;
  line-height: 1.6rem;
  text-align: center;
  margin: 2rem 0;
  position: relative;
  direction: ltr;
}
blockquote p {
    font-size: 1.9rem;
    font-family: "Great Vibes", cursive;
    line-height: 140%;
    font-weight: 400;
    display: inline;

}
blockquote::after, blockquote::before {
    color: transparent;
    background: 0 0;
    content: "";
    width: 2rem;
    height: 2rem;
    border: solid #934239;
    position: absolute;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
blockquote::before {
    top: 0;
    left: 0;
    bottom: auto;
    right: auto;
    font-size: 4rem;
    line-height: 0.1rem;
    margin-right: 0.1rem;
    border-width: 1px 0 0 1px;
}
blockquote::after {
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;
    font-size: 4rem;
    line-height: 0.1rem;
    margin-right: 0.1rem;
    border-width: 0 1px 1px 0;
}
h2{
  position: relative;
  color: #fc7100;
  color: rgba(0, 135, 206, 0.9);
  background: 0 0;
  font-size: 1.9em;
  font-style: normal;
  text-align: left;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
}
h2::before {
    content: "";
    width: 90px;
    height: 2px;
    position: absolute;
    bottom: -8px;
    background-image: linear-gradient(to right, #fc7100, #934239);
    background-image: linear-gradient(to right, rgba(0, 135, 206, 0.9), rgba(0, 70, 207, 0.9));
}
/* .animation-scroll{
  transition: -webkit-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              -moz-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              -o-transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              transform 1.2s cubic-bezier(0.4, 0.1, 0.2, 1),
              opacity 1.2s cubic-bezier(0.4, 0.1, 0.2, 1) !important;
} */
.container-image{
  width: 25%;
  max-width: 26.249994em;
  margin: 0;
  padding: 16px;
  float: none;
  position: relative;
  right: 4.16667%;
  left: auto;
}

.bloc-image{
  max-width: inherit;
  /* border: 1px red solid; */
  margin: 0 auto;
  padding: 0;
  border-radius: 8px;
  overflow: hidden; /*Garde l'image à l'interieur du container.*/
  opacity: 1;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}
a{
  transition: color 0.3s cubic-bezier(0.4, 0.1, 0.2, 1), background 0.3s cubic-bezier(0.4, 0.1, 0.2, 1);
  text-decoration: none;
  line-height: inherit;
}
img{
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
a img{
  border: none;
}
.bloc-image img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.3s ease, -webkit-box-shadow 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease, -moz-box-shadow 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease, box-shadow 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease, -moz-transform 0.3s ease, -o-transform 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease-in-out;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  -webkit-filter: brightness(1);
  -moz-filter: brightness(1);
  -ms-filter: brightness(1);
  filter: brightness(1);
  background: #fff;
  min-width: 100%;

}
.bloc-image img:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-filter: brightness(1.1);
  -moz-filter: brightness(1.1);
  -ms-filter: brightness(1.1);
  filter: brightness(1.1);
}
@media only screen and (max-width: 480px) { /*Mobile*/
  /* .row{
    max-width: 100em;
  } */
  .professeurs{
    margin:2% 0 2% 0;
  }
  .section-profs{
    flex-direction: column;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
  }
  .container-texte-profs{
    width: 100%;
  }
  .container-image{
    z-index: 5;
    width: 70%;
    padding:0;
    top: -50px;
  }
  h2{
    font-size: 1.5em;
  }
  blockquote p {
    font-size: 1.4em;
    line-height: 80%;
  }
}
</style>
