<template>
<div id="ModaleActualite" class="Modale" v-if="afficheModaleActualite">
  <div class="overlay"  v-on:click="basculeModaleActualite" >
  </div>
  <div class="contenuModale">
    <header>
      <!-- <div class="droite" v-on:click="basculeModaleActualite"> -->
      <!-- X -->
        <!-- <a href="#fermerModaleActualite" title="Fermer la fenètre" v-on:click="basculeModaleActualite" class="droite" >X</a> -->
      <!-- </div> -->

      <h2>Actualité <br> Duo Danse.
        <input type="button" id="btOk" class="droite" value="X" v-on:click="basculeModaleActualite" >
      </h2>
      <!-- <div class="droite"> -->

      <!-- </div> -->
    </header>
    <section>
      <formActualite/>
    </section>
  </div>
</div>
</template>

<script>
import formActualite from  '@/components/FormActualite.vue';

export default {
  name: 'ModaleActualite',
  props: ['afficheModaleActualite', 'basculeModaleActualite'],
  // computed: {
  //   console: () => console,
  // },
  components: {
    formActualite
  }
  // mounted: function(){
    // Cette fonction fait apparaitre ou disparaitre le formulaire de contact pour
    // test pendant programmation.
    // Lorsque le formulaire de contact sera operationnel cette fonction n'aura
    // plus d'utilité.
    // var keys = {};
    /*
     * Mise du code appelé en commun dans une
     * fonction que nous allons
     * abonnée à un écouteur d'évènement.
     */
    // function trackMultipleKeyStroke (e) {
    //     e = e || event;
    //     // e.which = e.which || e.keyCode;
    //     keys[e.keyCode] = e.type === 'keydown';
    //     /*
    //      * Cette partie constitue le code exécuté quand
    //      * Ctrl (17), Alt (18) et E (69)
    //      * sont enfoncées.
    //      */
    //     if (keys[17] && keys[18] && keys[70]) {
    //         // console.log('Ctrl + Alt + f');
    //         keys = {};
    //         var formulaire = document.getElementById("formulaire");
    //         // var formulaire = document.getElementsByClassName("container-formulaire");
    //         if (formulaire.style.display == "none"){
    //           formulaire.style.display = "block";
    //         }else{
    //           formulaire.style.display = "none";
    //         }
    //     }
    // }
    /*
     * Fonction de rétro-compatibilité pour
     * les navigateurs Internet Explorer.
     * Elle marchera dans tous les navigateurs
     * et demandera qui s'abonne, à quel évènement
     * et ce qu'il se passe quand l'évènement est
     * appelé / levé.
     */
    // function addEvent(element, event, func) {
    //     /*
    //      * Avons nous à faire à un vieil Internet Explorer ?
    //      */
    //     if (element.attachEvent) {
    //         /*
    //          * Abonnons nous alors comme Internet Explorer le propose.
    //          */
    //         return element.attachEvent('on' + event, func);
    //     } else {
    //         /*
    //          * Nous nous abonnons comme la spécification ECMAScript le propose.
    //          */
    //         return element.addEventListener(event, func, false);
    //     }
    // }
    /*
     * Appel de la fonction de rétro-compatibilité
     * que nous venons de créer pour abonner `window`
     * au évènement `keydown` et `keyup`
     */
    // addEvent(window, "keydown", trackMultipleKeyStroke);
  // }
};
</script>

<style scoped>
  .Modale {
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .contenuModale {
    position: relative;
    width: 95%;
    margin: 1% auto;
    padding: 8px 8px 8px 8px;
    border-radius: 5px;
    background: #eee;
    transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
  }

  .contenuModale header, .contenuModale footer {
    /* width: 100%; */
    border-bottom: 1px solid #e7e7e7;
    border-radius: 5px 5px 0 0;
    /* background-color: lightgrey; */
    background-color: rgba(0, 135, 206, 0.9);
    /* padding:15px; */
  }

  .contenuModale footer {
    border:none;
    border-top: 1px solid #e7e7e7;
    border-radius: 0 0 5px 5px;
  }

  .droite {
    float: right;
    width: 1em;
    right: 10px;
    text-align: right;
    font-weight: bold;
    font-size: 1.4em;
    color: white;
    text-decoration: none;

  }

  .contenuModale h2 {
    margin:0;
  }

  .contenuModale section {
    padding:15px 0 15px 0;
  }

  #btOk {
    cursor:pointer;
    color: rgba(255, 255, 255, 0.9);
    background-color: rgba(0, 135, 206, 0.9);
    font-size: .6em;
    padding: 0 0 0 0;
    margin: -.5em .5em 0 0;
  }

  blockquote, dd, div, dl, dt, form, h2, h4, h5, h6, li, ol, p, pre, td, th, ul {
      margin: 0;
      padding: 0;
      direction: ltr;
  }
  blockquote{
    text-align: center;
    quotes: "\201C""\201D";
    padding: 1.5rem;
    border: 0;
    line-height: 1.6rem;
    text-align: center;
    margin: 2rem 0;
    position: relative;
    direction: ltr;
  }
  blockquote p {
      font-size: 1.9rem;
      font-family: "Great Vibes", cursive;
      line-height: 140%;
      font-weight: 400;
      display: inline;

  }
  blockquote::after, blockquote::before {
      color: transparent;
      background: 0 0;
      content: "";
      width: 2rem;
      height: 2rem;
      border: solid #934239;
      position: absolute;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
  }
  blockquote::before {
      top: 0;
      left: 0;
      bottom: auto;
      right: auto;
      font-size: 4rem;
      line-height: 0.1rem;
      margin-right: 0.1rem;
      border-width: 1px 0 0 1px;
  }
  blockquote::after {
      bottom: 0;
      right: 0;
      top: auto;
      left: auto;
      font-size: 4rem;
      line-height: 0.1rem;
      margin-right: 0.1rem;
      border-width: 0 1px 1px 0;
  }
  h2{
    position: relative;
    color: #fc7100;
    color: rgba(0, 0, 0, 0.9);
    background: 0 0;
    font-size: 1.9rem;
    font-style: normal;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1.1;
    text-rendering: optimizeLegibility;
  }
  h2::before {
      content: "";
      width: 90px;
      height: 2px;
      position: absolute;
      bottom: -8px;
      /* background-image: linear-gradient(to right, #fc7100, #934239); */
      background-image: linear-gradient(to right, rgba(0, 135, 206, 0.9), rgba(0, 70, 207, 0.9));
  }
  a{
    color: rgb(79, 235, 230);
  }
  /************************************/
  @media only screen and (min-width: 480px) { /*Pc*/
    .contenuModale {
      max-width: 40%;
      /* width:40%; */
    }
  }
  @media only screen and (max-width: 480px) { /*Mobile*/
    .contenuModale {
      max-width: 80%;
      /* width:40%; */
    }
  }
</style>
